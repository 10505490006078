<template>
    <div>
      <div class="container mt-5">
        <h1 class="mb-4">Order Submission</h1>
        <form @submit.prevent="submitOrder">
          <div class="mb-3">
            <label for="title" class="form-label">Title</label>
            <input type="text" class="form-control" id="title" v-model="title" required>
          </div>
          <div class="mb-3">
            <label for="description" class="form-label">Description</label>
            <textarea class="form-control" id="description" v-model="description" required></textarea>
          </div>
          <div class="mb-3">
            <label class="form-label">Upload Files</label>
            <div class="input-group">
              <span class="input-group-text"><i class="bi bi-upload"></i></span>
              <input type="file" class="form-control" @change="handleFileUpload" multiple>
            </div>
          </div>
          <ul class="list-group mb-3">
            <li class="list-group-item" v-for="file in submissionFiles" :key="file.name">
              <i class="bi bi-file-earmark"></i> {{ file.name }}
              <button type="button" class="btn btn-danger btn-sm float-end" @click="removeFile(file)">Remove</button>
            </li>
          </ul>
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
  
      <div>
        <!-- Display the live order package data -->
        <ul v-if="liveOrderPackages.length">
          <li v-for="pkg in liveOrderPackages" :key="pkg.id">
            <!-- Display live order package details -->
            <div>
              <h4>Package Details</h4>
              <p>ID: {{ pkg.id }}</p>
              <p>Name: {{ pkg.name }}</p>
              <p>Price: {{ pkg.price }}</p>
              <p>Status: {{ pkg.status }}</p>
              <p>Delivery Day: {{ pkg.delivery_day }}</p>
              <p>Inclusions: {{ pkg.inclusions }}</p>
  
              <!-- Display live order details -->
              <h4>Live Order Details</h4>
              <p>Live Order ID: {{ pkg.live_order.id }}</p>
              <p>Highest Price: {{ pkg.live_order.highest_price }}</p>
              <p>Total Amount: {{ pkg.live_order.total_amount }}</p>
              <p>Payment Method: {{ pkg.live_order.payment_method }}</p>
              <p>Comments: {{ pkg.live_order.comments }}</p>
              <p>Bayer Id: {{ pkg.live_order.user_id }}</p>
              <!-- <p>Request File: <img :src="getImageUrl(pkg.live_order.req_file)" alt="Request File" /></p> -->
            </div>
          </li>
        </ul>
        <p v-else>No live order packages found.</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        title: '',
        description: '',
        submissionFiles: [], // Initialize submissionFiles as an array
        liveOrderPackages: [], // Array to store the retrieved data
        bayerId: '', // Store the Bayer ID here
        sellerId: '', // Store the seller ID here
        liveOrderID: '', // Store the live order ID here
      };
    },
    methods: {
      async getLiveOrderPackages() {
        try {
          // Retrieve the userId from localStorage
          const userId = localStorage.getItem('id');
  
          if (!userId) {
            throw new Error('User ID not found in local storage');
          }
  
          // Make a GET request to your Laravel backend
          const response = await axios.get(`/get_live_order_packages/${userId}`);
  
          // Store the data in the liveOrderPackages array
          this.liveOrderPackages = response.data;
          
          // Assuming you want to set the Bayer ID from the first package
          if (this.liveOrderPackages.length > 0) {
            this.bayerId = this.liveOrderPackages[0].live_order.user_id;
            this.liveOrderID = this.liveOrderPackages[0].live_order.id; // Set the live order ID
          }
        } catch (error) {
          console.error('Error fetching live order packages:', error);
        }
      },
      
      handleFileUpload(event) {
        // Get the selected files from the input
        const selectedFiles = Array.from(event.target.files);
        // Add new files to the existing files
        this.submissionFiles = [...this.submissionFiles, ...selectedFiles];
      },
  
      removeFile(file) {
        // Remove file from the submissionFiles array
        this.submissionFiles = this.submissionFiles.filter(f => f !== file);
      },
  
      submitOrder() {
  const sellerId = localStorage.getItem('id');
  // Handle form submission
  const formData = new FormData();
  formData.append('title', this.title);
  formData.append('description', this.description);
  formData.append('seller_id', sellerId); // Append seller ID
  formData.append('bayer_id', this.bayerId); // Append Bayer ID
  formData.append('live_order_id', this.liveOrderID); // Append live order ID
  this.submissionFiles.forEach(file => {
    formData.append('submissionFiles[]', file); // Append each file
  });

  // Send formData to your backend using Axios
  axios.post('/submit_order', formData)
    .then(response => {
      console.log('Order Submitted', response.data);

      // Show alert message after successful submission
      window.alert('Your order has been submitted successfully.');

      // Reset form fields
      this.title = '';
      this.description = '';
      this.submissionFiles = [];
    })
    .catch(error => {
      console.error('Error submitting order:', error);

      // Show alert message for errors
      window.alert('There was an error submitting your order. Please try again.');
    });
},
    },
  
    mounted() {
      // Fetch the data when the component is mounted
      this.getLiveOrderPackages();
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
  }
  .input-group-text {
    display: flex;
    align-items: center;
  }
  </style>
  