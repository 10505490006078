<template>
  <div>
    <h1>Order Page</h1>
    <div class="allitem">
      <div v-for="(packages, opportunityId) in groupedPackages" :key="opportunityId" class="card">
        <div class="card-body">
          <h5 class="card-title">Opportunity ID: {{ opportunityId }}</h5>
          <div v-if="paginatedPackages[opportunityId].length">
            <div v-for="pkg in paginatedPackages[opportunityId]" :key="pkg.id" class="card mb-3">
              <div class="card-body">
                <p>Package ID: {{ pkg.id }}</p>
                <p>Package Name: {{ pkg.name }}</p>
                <p>User ID: {{ pkg.user_id }}</p>
                <p>Seller ID: {{ pkg.seller_id }}</p>
                <p>Category ID: {{ pkg.category_id }}</p>
                <p>SubCategory ID: {{ pkg.subCategory_id }}</p>
                <p>Delivery Date: {{ pkg.delivery_day }}</p>
                <p>Revisions: {{ pkg.revisions }}</p>
                <p>Inclusions: {{ pkg.inclusions }}</p>
                <p>Price: {{ pkg.price }}</p>
                <!-- Add any other fields you want to display here -->

                <h3>Select package</h3>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch"
                         :id="'flexSwitchCheckChecked' + pkg.id"
                         :checked="selectedPackages[opportunityId] === pkg.id"
                         @change="selectPackage(opportunityId, pkg.id)">
                  <label class="form-check-label" :for="'flexSwitchCheckChecked' + pkg.id">Select package</label>
                </div>
              </div>
            </div>
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" href="#" @click.prevent="prevPackage(opportunityId)">Previous</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#" @click.prevent="nextPackage(opportunityId)">Next</a>
                </li>
              </ul>
            </nav>
          </div>
          <div v-else>
            <p>No packages to display.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        Attach your requirement
      </div>
      <div class="card-body">
        <h5 class="card-title">Add here</h5>
        <p class="card-text">Add or attach your requirement details</p>
        <div class="form-floating">
          <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea" v-model="comments"></textarea>
          <label for="floatingTextarea">Comments</label>
        </div>
        <div class="input-group mb-3">
          <input type="file" class="form-control" id="inputGroupFile02" @change="handleFileUpload">
          <label class="input-group-text" for="inputGroupFile02">Upload</label>
        </div>
        <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#createOrderModal">Create Order</button>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="createOrderModal" tabindex="-1" aria-labelledby="createOrderModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="createOrderModalLabel">Select your payment method</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="form-check">
        <input class="form-check-input" type="radio" name="paymentMethod" id="visaMaster" value="Visa/Master" v-model="selectedPaymentMethod">
        <label class="form-check-label" for="visaMaster">Visa or Master</label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="paymentMethod" id="payoneer" value="Payoneer" v-model="selectedPaymentMethod" checked>
        <label class="form-check-label" for="payoneer">Payoneer</label>
      </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="confirmOrder">Confirm Order</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Selected Packages Card -->
    <div class="selected-packages card mt-5">
      <div class="card-header">
        Selected Packages
      </div>
      <div class="card-body">
        <div v-for="(packageId, opportunityId) in selectedPackages" :key="packageId">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title">Opportunity ID: {{ opportunityId }}</h5>
              <p v-if="getSelectedPackageDetails(opportunityId, packageId)">Package ID: {{ getSelectedPackageDetails(opportunityId, packageId).id }}</p>
              <p v-if="getSelectedPackageDetails(opportunityId, packageId)">Package Name: {{ getSelectedPackageDetails(opportunityId, packageId).name }}</p>
              <p v-if="getSelectedPackageDetails(opportunityId, packageId)">User ID: {{ getSelectedPackageDetails(opportunityId, packageId).user_id }}</p>
              <p v-if="getSelectedPackageDetails(opportunityId, packageId)">Seller ID: {{ getSelectedPackageDetails(opportunityId, packageId).seller_id }}</p>
              <p v-if="getSelectedPackageDetails(opportunityId, packageId)">Category ID: {{ getSelectedPackageDetails(opportunityId, packageId).category_id }}</p>
              <p v-if="getSelectedPackageDetails(opportunityId, packageId)">SubCategory ID: {{ getSelectedPackageDetails(opportunityId, packageId).subCategory_id }}</p>
              <p v-if="getSelectedPackageDetails(opportunityId, packageId)">Delivery Date: {{ getSelectedPackageDetails(opportunityId, packageId).delivery_day }}</p>
              <p v-if="getSelectedPackageDetails(opportunityId, packageId)">Revisions: {{ getSelectedPackageDetails(opportunityId, packageId).revisions }}</p>
              <p v-if="getSelectedPackageDetails(opportunityId, packageId)">Inclusions: {{ getSelectedPackageDetails(opportunityId, packageId).inclusions }}</p>
              <p v-if="getSelectedPackageDetails(opportunityId, packageId)">Price: {{ getSelectedPackageDetails(opportunityId, packageId).price }}</p>
              <!-- Add any other fields you want to display here -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 <!-- Highest Price Card -->
 <div class="highest-price card mt-5">
  <div class="card-header">
    Highest Price
  </div>
  <div class="card-body">
    <p v-if="highestPrice !== null && highestPrice !== undefined">
      The highest price among selected packages is: {{ highestPrice }}
    </p>
    <p v-else>
      No packages selected.
    </p>

    <p v-if="highestPrice !== null && highestPrice !== undefined">
      Service Charge: {{ (highestPrice * 0.05).toFixed(2) }} <!-- Service charge calculated as 5% of highestPrice -->
    </p>

    <p v-if="highestPrice !== null && highestPrice !== undefined">
      Total: {{ highestPrice + (highestPrice * 0.05) }} <!-- Total calculated as highestPrice + service charge -->
    </p>
  </div>
</div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      selectedItems: [],
      packageDetails: [],
      groupedPackages: {},
      currentPages: {},
      selectedPackages: [],
      // selectedPackage: [],
      selectedPaymentMethod: 'Payoneer', // Default selected payment method
    comments: '', // Hold textarea comments
    req_file: '',
   
    
    };
  },
  computed: {
    paginatedPackages() {
      let paginated = {};
      Object.keys(this.groupedPackages).forEach(opportunityId => {
        let packages = this.groupedPackages[opportunityId];
        let currentPage = this.currentPages[opportunityId] || 1;
        let start = (currentPage - 1) * 1; // 1 package per page
        let end = start + 1;
        paginated[opportunityId] = packages.slice(start, end);
      });
      return paginated;
    },

    highestPrice() {
    // Get all package IDs from selectedPackages
    const selectedPackageIds = Object.values(this.selectedPackages);

    // Filter out undefined or null values
    const selectedPackageDetails = selectedPackageIds.map(packageId => {
      return this.packageDetails.find(pkg => pkg.id === packageId);
    }).filter(pkg => pkg);

    // Find the maximum price among selected packages
    if (selectedPackageDetails.length > 0) {
      return Math.max(...selectedPackageDetails.map(pkg => pkg.price));
    } else {
      return null; // or any default value you prefer
    }
  }
  },
  created() {
    if (this.$route.query.selectedItems) {
      this.selectedItems = JSON.parse(this.$route.query.selectedItems);
      this.fetchPackageDetails();
    }
  },
  methods: {
    fetchPackageDetails() {
      const requests = this.selectedItems.map(item =>
        axios.get('/packages', {
          params: {
            opportunity_id: item.itemId,
            user_id: item.userId
          }
        })
      );

      Promise.all(requests)
        .then(responses => {
          this.packageDetails = responses.flatMap(response => response.data);
          this.groupPackagesByOpportunityId();
          console.log('Fetched package details:', this.packageDetails); // Debug information
        })
        .catch(error => {
          console.error('There was an error fetching the package details:', error);
        });
    },
    groupPackagesByOpportunityId() {
      this.groupedPackages = this.packageDetails.reduce((groups, pkg) => {
        const { opportunity_id } = pkg;
        if (!groups[opportunity_id]) {
          groups[opportunity_id] = [];
        }
        groups[opportunity_id].push(pkg);
        // Initialize currentPages for each opportunityId
        if (!this.currentPages[opportunity_id]) {
          this.currentPages[opportunity_id] = 1;
        }
        return groups;
      }, {});
    },
    prevPackage(opportunityId) {
      if (this.currentPages[opportunityId] > 1) {
        this.currentPages[opportunityId]--;
      }
    },
    nextPackage(opportunityId) {
      if (this.currentPages[opportunityId] < this.groupedPackages[opportunityId].length) {
        this.currentPages[opportunityId]++;
      }
    },
    selectPackage(opportunityId, packageId) {
if (this.selectedPackages[opportunityId] === packageId) {
  this.selectedPackages[opportunityId] = undefined; // or null, or delete it as per your use case
} else {
  this.selectedPackages[opportunityId] = packageId;
}
console.log('Selected packages:', this.selectedPackages); // Debug information
},

    getSelectedPackageDetails(opportunityId, packageId) {
      const packages = this.groupedPackages[opportunityId] || [];
      return packages.find(pkg => pkg.id === packageId);
    },



  handleFileUpload(event) {
  const file = event.target.files[0];
  if (file) {
    this.req_file = file;
  }
},


//     handleFileChange(event) {
//   const file = event.target.files[0];
//   if (file) {
//     this.profile_photo = file;
//   }
// },


confirmOrder() {
  // Get user ID and token from local storage
  const userId = localStorage.getItem('id');
  const token = localStorage.getItem('token');
  console.log('Selected Packages:', this.selectedPackages);

  // Map selected packages to required format
  let selectedPackagesData = [];

  // Iterate through selected packages and extract details
  for (let [opportunityId, packageId] of Object.entries(this.selectedPackages)) {
    let packageDetails = this.getSelectedPackageDetails(opportunityId, packageId);
    if (packageDetails) {
      selectedPackagesData.push({
        opportunityId: opportunityId,
        packageId: packageId,
        id: packageDetails.id,
        name: packageDetails.name,
        user_id: packageDetails.user_id,
        seller_id: packageDetails.seller_id,
        category_id: packageDetails.category_id,
        subCategory_id: packageDetails.subCategory_id,
        delivery_day: packageDetails.delivery_day,
        revisions: packageDetails.revisions,
        inclusions: packageDetails.inclusions,
        price: packageDetails.price
        // Add any other fields you want to include
      });
    }
  }
  let formData = new FormData();
  formData.append('req_file', this.req_file);

  // Calculate service charge and total amount
  const serviceCharge = this.highestPrice * 0.05;
  const totalAmount = this.highestPrice + serviceCharge;

  // Prepare the payload to send to the backend (for file upload and other data)
  const liveOrderData = {
    highestPrice: this.highestPrice,
    serviceCharge: serviceCharge,
    totalAmount: totalAmount,
    paymentMethod: this.selectedPaymentMethod,
    comments: this.comments,
    user_id: userId, // Include user ID
    token: token, // Include token
    selectedPackages: selectedPackagesData
  };

// Append formData directly to liveOrderData or as a separate field
liveOrderData.req_file = formData.get('req_file');


  // Send a POST request to your backend endpoint
  axios.post('/store-live-order', liveOrderData, {
    headers: {
      'Content-Type': 'multipart/form-data' // Use multipart form-data for file upload
    }
  })
  .then(response => {
    console.log('Order confirmed successfully:', response.data);
    // Optionally, perform actions after successful order confirmation
    alert('Order successfully confirmed!');
    // For example, show a success message or redirect to a confirmation page
  })
  .catch(error => {
    console.error('Error confirming order:', error);
    alert('Error confirming order. Please try again.');
    // Handle error scenarios
  });
}






  }
};
</script>

<style scoped>
/* Add your styles here */
.allitem {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px !important;
}

.card {
  background-color: white;
  border: 2px solid rgb(170, 167, 167);
  margin: 10px;
  width: 300px;
  box-shadow: 0 4px 8px rgb(94, 93, 93);
  overflow: hidden;
  transition: transform 0.2s;
}
</style>