<template>
  <div style="height: 100%; width: 100%; background-color: red;">
    <div style="display: flex;">
      <div class="left-part">
        <router-link to="/" class="navbar-brand">
          <div class="Logo">ProLance<b>.</b></div>
            
            </router-link>
      </div>
      
      <ul class="nav justify-content-center middle-part">
        <li class="nav-item">
          <router-link to="/" class="nav-link active" aria-current="page">Home</router-link>
        </li>
        <li class="nav-item">
    <!-- Use router-link instead of a tag -->
    <router-link to="/aboutus" class="nav-link">About Us</router-link>
  </li>
        <li class="nav-item">
          <router-link to="/helpandsupport" class="nav-link">Help and support</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/becomeseller" class="nav-link">Become a Seller</router-link>
        </li>
      </ul>

      <div class="right-part">

        <template v-if="!isLoggedIn">



        <router-link to="/register"><button type="button" class="btn btn-outline-light">Sign In</button></router-link>
        <router-link to="/login"><button type="button" class="btn btn-outline-light">Login</button></router-link>
      </template>

      <template v-else>
        <button class="btn btn-outline-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                <span style="margin-right: 10px;">{{ firstName }}</span>
                <img :src="profilePhoto" alt="IMG" width="30" height="30" style="border-radius: 50%;">
              </button>
            </template>
        

    </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoggedIn: Boolean,
    firstName: String,
    profilePhoto: String
  },
    
}
</script>

<style lang="scss" scoped>

.left-part{
  width: 20%;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(7, 7, 7);
}

.middle-part{
  display: flex;
  width: 60%;
}

.right-part{
  width: 20%;
  text-align: center;
  color: white;
  background-color: rgb(7, 7, 7);
  justify-content: center;
  align-items: center;
  display: flex;
}
section {
  height: 10dvh;
  display: flex;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: black;
  display: flex;
  align-items: center;
  justify-items: center;
}

.btn-outline-light{
  margin: 10px;
}

.Logo{
    display:flex;
    font-size:40px;
    font-weight: 800;
    color: #3d535f;
    letter-spacing: 3px;
    cursor:pointer;
    text-transform: uppercase;
  }
  
  b{
    font-size:50px;
    color:#7f00ff;
  }

</style>