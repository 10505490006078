<template>
    <div class="get-started-page">
      <div class="header">
        <div class="Logo">ProLance<b>.</b></div>
      </div>
      <div class="content">
        <div class="image-section">
          <img src="@/assets/seller/seller.jpg" alt="Profile Tips" class="profile-tips-image">
        </div>
        <div class="overview-section">
          <h3>Creating a Successful Profile on ProLance</h3>
          <div class="overview-content">
            <div class="overview-item">
              <img src="@/assets/seller/user.png" alt="First Impression Icon" class="icon">
              <div>
                <h4>Make a Strong First Impression</h4>
                <p>Your first impression matters! Create a profile that will stand out from the crowd on ProLance.</p>
              </div>
            </div>
            <div class="overview-item">
              <img src="@/assets/seller/user-add.png" alt="Credibility Icon" class="icon">
              <div>
                <h4>Build Credibility</h4>
                <p>Add credibility by linking out to your relevant professional networks.</p>
              </div>
            </div>
            <div class="overview-item">
              <img src="@/assets/seller/icon-skills.png" alt="Skills Icon" class="icon">
              <div>
                <h4>Describe Your Skills</h4>
                <p>Accurately describe your professional skills to help you get more work.</p>
              </div>
            </div>
            <div class="overview-item">
              <img src="@/assets/seller/icon-picture.png" alt="Profile Picture Icon" class="icon">
              <div>
                <h4>Upload a Profile Picture</h4>
                <p>Put a face to your name! Upload a profile picture that clearly shows your face.</p>
              </div>
            </div>
            <div class="overview-item">
              <img src="@/assets/seller/icon-security.png" alt="Security Icon" class="icon">
              <div>
                <h4>Verify Your ID</h4>
                <p>To keep our community secure for everyone, we may ask you to verify your ID.</p>
              </div>
            </div>
          </div>
          <button class="continue-button" @click="continueToNextStep">Continue</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OverView',
    data() {
      return {
        firstName: localStorage.getItem('firstName') || 'User',
      };
    },
    methods: {
      continueToNextStep() {
        this.$router.push('/personal-info');
      }
    }
  };
  </script>
  
  <style scoped>
  .get-started-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 20px;
  }
  
  .Logo {
    display: flex;
    font-size: 40px;
    font-weight: 800;
    color: #3d535f;
    letter-spacing: 3px;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .Logo b {
    font-size: 50px;
    color: #7f00ff;
  }
  
  .content {
    display: flex;
    width: 100%;
    max-width: 1200px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .profile-tips-image {
    width: 100%;
    height: auto;
    max-width: 600px;
    max-height: 400px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .overview-section {
    flex: 1;
    padding: 20px;
  }
  
  h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .overview-content {
    margin-bottom: 20px;
  }
  
  .overview-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  h4 {
    font-size: 1.2em;
    margin: 0;
  }
  
  p {
    font-size: 1em;
    margin: 5px 0 0;
  }
  
  .continue-button {
    display: block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #00ff22;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .continue-button:hover {
    background-color: #00b32d;
  }
  </style>
  