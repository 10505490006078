<template>
  <div class="subcategory-page">
    <h1>Subcategory: {{ sub_c_title }}</h1>
    <p>ID: {{ sub_c_id }}</p>
    <h2 class="mb-4">Opportunities with Images</h2>

    <!-- Count of selected items -->
    <div class="selected-count">
      Selected items: {{ selectedCount }}
    </div>

    <div class="allitem">
      <div v-for="opportunity in opportunities" :key="opportunity.id" class="opportunity card p-3 mb-4">
        <input type="checkbox" class="opportunity-checkbox" :id="'checkbox-' + opportunity.id" @change="toggleSelection(opportunity)">
        <label class="checkbox-label" :for="'checkbox-' + opportunity.id"></label>

        <h5 class="card-title">{{ opportunity.opportunity_title }}</h5>

        <div class="userdata" v-if="opportunity.user">
          <img v-if="opportunity.user.profile_photo_url" :src="opportunity.user.profile_photo_url" alt="User Profile Photo" class="rounded-circle" style="width: 50px; height: 50px;">
          <p>{{ opportunity.user.first_name }} {{ opportunity.user.last_name }}</p>
        </div>

        <div v-if="opportunity.oppo_images && opportunity.oppo_images.length > 0" :id="'carousel' + opportunity.id" class="carousel slide" data-bs-touch="false">
          <div class="carousel-inner">
            <div v-for="(image, index) in opportunity.oppo_images" :key="image.id" :class="['carousel-item', { active: index === 0 }]">
              <img :src="image.full_path" class="d-block w-100 cardImage" :alt="'Image for ' + opportunity.opportunity_title">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" :data-bs-target="'#carousel' + opportunity.id" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" :data-bs-target="'#carousel' + opportunity.id" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <div v-else>
          <p>No images available for this opportunity.</p>
        </div>
      </div>
    </div>

    <!-- Modal structure with v-if directive -->
    <div class="modal fade show" tabindex="-1" role="dialog" style="display: block;" v-if="showModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirm Selection</h5>
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>You have selected three items. Do you want to confirm this selection?</p>
            <ul>
              <li v-for="item in selectedItems" :key="item.id">
                ID: {{ item.id }}, Title: {{ item.opportunity_title }}, User ID: {{ item.user.id }}
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
            <button type="button" class="btn btn-primary" @click="confirmSelection">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['sub_c_title', 'sub_c_id'],
  data() {
    return {
      opportunities: [],
      selectedCount: 0,
      showModal: false, // Flag to control modal display
      selectedItems: [] // Array to store selected items' information
    };
  },
  mounted() {
    console.log('Subcategory Sub_C_title:', this.sub_c_title);
    console.log('Subcategory ID:', this.sub_c_id);
    this.fetchOpportunities();
  },
  methods: {
    fetchOpportunities() {
      axios.get(`/getOpportunities/${this.sub_c_id}`)
        .then(response => {
          this.opportunities = response.data;
        })
        .catch(error => {
          console.error('There was an error fetching the opportunities:', error);
        });
    },
    toggleSelection(opportunity) {
      if (opportunity.selected) {
        this.selectedCount--;
        this.selectedItems = this.selectedItems.filter(item => item.id !== opportunity.id);
      } else {
        this.selectedCount++;
        this.selectedItems.push(opportunity);
      }
      opportunity.selected = !opportunity.selected;

      if (this.selectedCount === 3) {
        this.showModal = true;
      }
    },
    closeModal() {
      this.showModal = false;
    },
    confirmSelection() {
      if (confirm('Are you sure you want to confirm the selection of these items?')) {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('id');
        console.log('user_Token:', token);
        console.log('user_ID:', userId);

        if (!token || !userId) {
          if (confirm('Please login to proceed. Click OK to go to the login page.')) {
            this.$router.push({ name: 'login' }); //go to login page
          }
        } else {
          const selectedDetails = this.selectedItems.map(item => ({
            itemId: item.id,
            title: item.opportunity_title,
            userId: item.user.id
          }));

          this.$router.push({
            name: 'OrderPage',
            query: { selectedItems: JSON.stringify(selectedDetails) }
          });
        }
      } else {
        this.closeModal();
      }
    }
  }
};
</script>

<style scoped>
/* Add your styles here */
.subcategory-page {
  font-family: 'Poppins', sans-serif;
  color: #3d535f;
  padding: 20px;
}

.subcategory-page h1 {
  font-size: 2.5em;
  color: #7f00ff;
}

.subcategory-page p {
  font-size: 1.2em;
}

.opportunity {
  margin-bottom: 20px;
  position: relative; /* Ensure the checkbox is positioned relative to the card */
}

.opportunity-checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10; /* Ensure checkbox is above other content */
}

.checkbox-label {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  background-color: #fff; /* Ensure it's clickable */
  opacity: 0; /* Hide the actual checkbox */
  cursor: pointer; /* Pointer cursor on label */
}

.allitem {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px !important;
}

.card {
  background-color: white;
  border: 2px solid rgb(170, 167, 167);
  margin: 10px;
  width: 300px;
  box-shadow: 0 4px 8px rgb(94, 93, 93);
  overflow: hidden;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.cardImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.userdata {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  padding-bottom: 5px;
}
</style>
