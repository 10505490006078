<template>
    <div>
      <div>
        <FrontHeader />
      </div>
  
      <div>
        <HelpandSupport />
      </div>
      <div>
        <FooterPart />
      </div>
    
  
    </div>
  </template>
  
  <script>
   import FrontHeader from '@/components/FrontHeader.vue';
  import FooterPart from '@/components/FooterPart.vue';
  import HelpandSupport from '@/components/help/HelpandSupport.vue';
 
  
  
    export default {
      
  
      components:{
        FrontHeader,
      HelpandSupport,
      FooterPart
      
  }
      
    }
  </script>
  
  <style lang="scss" scoped>
  
  
  </style>