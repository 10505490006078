<template>
  <div class="image-gallery-page">
    <div class="header">
      <div class="Logo">ProLance<b>.</b></div>
    </div>
    <div class="main-content">
      <div class="content">
        <h3>Gig Image Guidelines</h3>
        <ul class="guidelines">
          <li>Images should be in JPG, PNG, or GIF format.</li>
          <li>Each image should not exceed 5MB.</li>
          <li>Ensure the images are clear and professional.</li>
          <li>You can upload up to 3 images.</li>
        </ul>

        <div class="image-upload">
          <label v-for="(image, index) in images" :key="index" class="image-upload-item">
            <input
              type="file"
              ref="fileInputs"
              @change="handleImageUpload($event, index)"
              accept="image/jpeg, image/png"
              :disabled="image !== null"
            />
            <img v-if="image" :src="image" alt="Uploaded Image" class="uploaded-image" @click="triggerFileInput(index)" />
            <div v-else class="placeholder" @click="triggerFileInput(index)">Choose File</div>
          </label>
        </div>

        <button @click="submitImages" :disabled="images.length === 0" class="btn btn-primary">
          Finish
        </button>
      </div>

      <div class="video-section">
        <video controls class="edit-video">
          <source src="@/assets/seller/edit.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      images: [null, null, null], // Initially allowing 3 image slots
      userId: null,
      sellerId: null,
      opportunityId: null,
      token: null
    };
  },

  mounted() {
    this.token = localStorage.getItem('token');
    this.userId = localStorage.getItem('id'); // Retrieve the userId stored as 'id'
    this.opportunityId = this.$route.query.opportunity_id;
    this.sellerId = this.$route.query.seller_id;

    console.log('Mounted - Seller ID:', this.sellerId);
    console.log('Mounted - User ID:', this.userId);
    console.log('Mounted - Opportunity ID:', this.opportunityId);
  },

  methods: {
    handleImageUpload(event, index) {
      const file = event.target.files[0];
      if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.images[index] = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert('Please upload a valid JPG or PNG image.');
      }
    },
    triggerFileInput(index) {
      if (this.$refs.fileInputs && this.$refs.fileInputs[index]) {
        this.$refs.fileInputs[index].click();
      }
    },
    async submitImages() {
      const formData = new FormData();
      this.images.forEach((image, index) => {
        if (image) {
          formData.append('images[]', this.$refs.fileInputs[index].files[0]);
        }
      });
      formData.append('opportunity_id', this.opportunityId);
      formData.append('user_id', this.userId);
      formData.append('seller_id', this.sellerId);

      try {
        const response = await axios.post('/uploadOpp-Images', formData, {
          headers: {
            Authorization: `Bearer ${this.token}`, // Include the token in the Authorization header
            'X-User-Id': this.userId
          }
        });
        console.log('Images submitted:', response.data.images);
        alert('Images uploaded successfully!');
        this.images = [null, null, null];
        this.$router.push('/sellerdashboard');
      } catch (error) {
        console.error('Error uploading images:', error);
        alert('There was an error uploading your images. Please try again.');
      }
    }
  }
};
</script>

  <style scoped>
  .image-gallery-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .header {
    display: flex;
    justify-content: flex-start;
    padding: 20px 0;
  }
  
  .Logo {
    font-size: 40px;
    font-weight: 800;
    color: #3d535f;
    letter-spacing: 3px;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .Logo b {
    font-size: 50px;
    color: #7f00ff;
  }
  
  .main-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1; /* Ensures content and video take full height */
    margin-top: 20px; /* Adjust as needed */
  }
  
  .content {
    width: calc(50% - 10px); /* Adjust width to fit your design */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .guidelines {
    list-style-type: disc;
    margin-bottom: 20px;
    padding-left: 20px;
  }
  
  .image-upload {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    align-items: flex-start;
  }
  
  .image-upload-item {
    position: relative;
    width: calc(33.33% - 20px);
    height: 200px;
    border: 1px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .uploaded-image {
    max-width: 100%;
    max-height: 100%;
  }
  
  .placeholder {
    text-align: center;
    color: #7f00ff;
    font-weight: bold;
  }
  
  .btn-primary {
    padding: 10px 20px;
    background-color: #7f00ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-end;
  }
  
  .btn-primary:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
  }
  
  .btn-primary:hover {
    background-color: #5700b3;
  }
  
  .video-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
  }
  
  .edit-video {
    width: 100%;
    height: auto;
    max-height: 400px;
  }
 
  </style>