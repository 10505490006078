import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RegisterForm from '../views/RegisterForm.vue'
import LoginPage from '../views/LoginPage.vue'
import BecomeSeller from '../views/BecomeSeller.vue'
import AboutUs from '../views/AboutUs.vue'
import HelpandSupport from '../views/HelpandSupport.vue'
import GraphicsDesign from '../views/GraphicsDesign.vue'
import DigitalMarketing from '../views/DigitalMarketing.vue'
import WritingTranslation from '../views/WritingTranslation.vue'
import VideoAnimation from '../views/VideoAnimation.vue'
import MusicAudio from '../views/MusicAudio.vue'
import ProgrammingTech from '@/views/ProgrammingTech.vue';
import BuyerPage from '@/views/BuyerPage/BuyerPage.vue';
import OverView from '@/views/OverView.vue';
import SellerPath from '@/views/SellerPath/SellerPath.vue';
import PersonalInfoPage from '@/views/SellerPath/PersonalInfoPage.vue';
import ProfessionalInfoPage from '@/views/SellerPath/ProfessionalInfoPage.vue';
import AccountSecurityPage from '@/views/SellerPath/AccountSecurityPage.vue';
import CreateOpportunity from '@/views/SellerPath/CreateOpportunity.vue';
import PackagesPage from '@/views/SellerPath/PackagesPage.vue';
import ImageGallery from '@/views/SellerPath/ImageGallery.vue';
import SellerDashboard from '@/views/Dashboard/SellerDashboard.vue';
// import ChatPage from '@/views/Dashboard/ChatPage.vue';
// import LogoDesign from '../views/SubCategory/LogoPage.vue';
import SubcategoryPage from '../views/SubCategory/SubcategoryPage.vue';
import OrderPage from '../views/OrderSection/OrderPage.vue';
import OrderDetails from '../views/OrderSection/OrderDetails.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/aboutus',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutUs
  },

  {
    path: '/register',
    name: 'register',
    component: RegisterForm
  },

  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },

  {
    path: '/becomeseller',
    name: 'becomeseller',
    component:BecomeSeller
  },

  {
    path: '/aboutus',
    name: 'about us',
    component:AboutUs
  },
  {
    path: '/helpandsupport',
    name: 'help and support',
    component:HelpandSupport
  },

  {
    path: '/graphicsdesign',
    name: 'graphicsdesign',
    component:GraphicsDesign
  },


  {
    path: '/digitalmarketing',
    name: 'digitalmarketing',
    component:DigitalMarketing
  },

  {
    path: '/writing',
    name: 'writing',
    component:WritingTranslation
  },

  {
    path: '/video',
    name: 'video',
    component:VideoAnimation
  },
  {
    path: '/music',
    name: 'music',
    component:MusicAudio
  },


  {
    path: '/programming',
    name: 'programming',
    component: ProgrammingTech  
  },


  {
    path: '/buyer',
    name: 'buyer',
    component: BuyerPage  
  },

  {
    path: '/overview',
    name: 'overview',
    component: OverView
  },

  {
    path: '/sellerpath',
    name: 'sellerpath',
    component: SellerPath
  },
  {
    path: '/personal-info',
    name: 'PersonalInfoPage',
    component: PersonalInfoPage
  },

  {
    path: '/professional-info',
    name: 'ProfessionalInfoPage',
    component: ProfessionalInfoPage
  },
  {
    path: '/account',
    name: 'account',
    component: AccountSecurityPage
  },
  {
    path: '/createopportunity',
    name: 'createopportunity',
    component: CreateOpportunity
  },


  {
    path: '/package',
    name: 'package',
    component: PackagesPage, // Ensure the correct component is used
    props: route => ({
      sellerId: route.query.seller_id,
      categoryId: route.query.category_id,
      subCategoryId: route.query.subCategory_id,
      professionId: route.query.profession_id,
      opportunityData: route.params.opportunityData,
      
   
        opportunityId: route.query.opportunity_id
     
    })
  },

  {
    path: '/imagegallery',
    name: 'imagegallery',
    component: ImageGallery,
    props: route => ({
      sellerId: route.query.seller_id,
      categoryId: route.query.category_id,
      subCategoryId: route.query.subCategory_id,
      professionId: route.query.profession_id,
      opportunityData: route.params.opportunityData,

   
        opportunityId: route.query.opportunity_id
     
    })
  },

  {
    path: '/sellerdashboard',
    name: 'SellerDashboard',
    component: SellerDashboard
  },

  // {
  //   path: '/logo-design',
  //   name: 'LogoDesign',
  //   component: LogoDesign,
  //   props: route => ({ valueName: route.params.valueName })
    
  // },

   {
    path: '/subcategory',
    name: 'subCategory',
    component: SubcategoryPage,
    props: route => ({ sub_c_title: route.query.sub_c_title, sub_c_id: route.query.sub_c_id })
    
  },

  {
    path: '/order',
    name: 'OrderPage',
    component: OrderPage
  },

  {
    path: '/orderdetails',
    name: 'OrderDetails',
    component: OrderDetails
  }


  // {
  //   path: '/chat/:chatId',
  //   name: 'ChatPage',
  //   component: ChatPage,
  //   props: true,
  // },
];




const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
