<template>
    <div class="help-section">
      <h1>How can we help you?</h1>
      <div class="search-bar">
        <input type="text" placeholder="Search..." class="search-input">
        <button>Search</button>
      </div>
  
     
      
    </div>
     <!-- Card Title Part -->
     <h2 class="card-title">Search by Category</h2>
      <div class="card-container">
        <!-- Card 1 -->
        <div class="card">
            <div class="card-icon">
          <img src="@/assets/help/profile.gif" alt="Icon 1" class="icon">
        </div>
        <div class="card-content">
          <h3>Category 1</h3>
          <p>Your Account</p>
        </div>
      </div>
        <!-- Card 2 -->
        <div class="card">
            <div class="card-icon">
          <img src="@/assets/help/presentation.gif" alt="Icon 1" class="icon">
        </div>
          <h3>Category 2</h3>
          <p>Gig Publish</p>
        </div>
        <!-- Card 3 -->
        <div class="card">
            <div class="card-icon">
          <img src="@/assets/help/handshake.gif" alt="Icon 1" class="icon">
        </div>
          <h3>Category 3</h3>
          <p>Buying On ProLance</p>
        </div>
        <!-- Card 4 -->
        <div class="card">
            <div class="card-icon">
          <img src="@/assets/help/laptop.gif" alt="Icon 1" class="icon">
        </div>
          <h3>Category 4</h3>
          <p>Selling on ProLance</p>
        </div>
        <!-- Card 5 -->
        <div class="card">
            <div class="card-icon">
          <img src="@/assets/help/meteor-rain.gif" alt="Icon 1" class="icon">
        </div>
          <h3>Category 5</h3>
          <p>Freedback</p>
        </div>
        <!-- Card 6 -->
        <div class="card">
            <div class="card-icon">
          <img src="@/assets/help/social-care.gif" alt="Icon 1" class="icon">
        </div>
          <h3>Category 6</h3>
          <p>Trust and Safety</p>
        </div>
        </div>
  </template>
  
  
  <script>
  export default {
   
  }
  </script>
  
  <style lang="scss" scoped>
  .help-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('~@/assets/seller/qa.jpg');
    background-size: cover;
  }
  
  h1 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #fcfbfb; 
    text-shadow: 2px 2px 4px #000;
    
  }
  
  .search-bar {
    display: flex;
    align-items: center;
    margin-top: 20px; 
  }
  
  .search-bar input[type="text"] {
    padding: 10px;
    margin-right: 20px;
    border-radius: 15px;
    border: 1px solid #ccc;
  }
  
  .search-bar button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .search-bar button:hover {
    background-color: #0056b3;
  }

  .card-title {
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #0a0a0a;
  
  text-align: center;
  
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
}

.card {
  width: 300px;
  background-color: #fff;
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card h3 {
  font-size: 18px;
  color: #333;
}

.card p {
  font-size: 14px;
  color: #666;
}


.icon {
  width: 50px; 
  height: 50px;}





  </style>
  