<template>
    <div class="chat-card card mb-3">
      <div class="card-body">
        <div class="user-info">
          <p><strong>Welcome, {{ firstName }}!</strong></p>
        </div>
        <div class="chat-messages">
          <div v-for="(message, index) in messages" :key="index" :class="['message', message.isMine ? 'my-message' : 'other-message']">
            <div class="message-content">
              <p class="mb-1"><strong>{{ message.user_name }}</strong></p>
              <p class="mb-0">{{ message.text }}</p>
              <small class="text-muted">{{ formatDate(message.created_at) }}</small>
            </div>
          </div>
        </div>
        <div class="message-input">
          <input v-model="newMessage" @keyup.enter="sendMessage" placeholder="Type a message" />
          <button @click="sendMessage">Send</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'ChatCard',
    data() {
      return {
        messages: [],
        newMessage: '',
        firstName: localStorage.getItem('firstName') || 'User',
        userId: localStorage.getItem('id')
      };
    },
    created() {
      this.fetchMessages();
      setInterval(this.fetchMessages, 2000); // Refresh messages every 2 seconds
  
      // Log localStorage values for debugging
      const token = localStorage.getItem('token');
      const firstName = localStorage.getItem('firstName');
      const profilePhoto = localStorage.getItem('profilePhoto');
      const role = localStorage.getItem('role');
      const id = localStorage.getItem('id');
      
      console.log('Token:', token);
      console.log('First Name:', firstName);
      console.log('Profile Photo:', profilePhoto);
      console.log('Role:', role);
      console.log('ID:', id);
    },
    methods: {
      fetchMessages() {
        axios.get('/chat-messages-get')
          .then(response => {
            const userId = this.userId;
            this.messages = response.data.map(message => {
              return {
                ...message,
                isMine: message.user_id == userId // Set isMine based on userId
              };
            });
          })
          .catch(error => {
            console.error('Error fetching messages:', error);
          });
      },
      sendMessage() {
        const userId = this.userId;
        const firstName = this.firstName;
  
        if (!userId || !firstName || !this.newMessage.trim()) {
          return;
        }
  
        axios.post('/chat-messages-send', {
          user_id: userId,
          user_name: firstName,
          text: this.newMessage,
        })
        .then(response => {
          this.messages.push({
            ...response.data,
            isMine: true // Set isMine to true for the current user's message
          });
          this.newMessage = '';
        })
        .catch(error => {
          console.error('Error sending message:', error);
        });
      },
      formatDate(dateString) {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
      }
    },
  };
  </script>
  
  <style scoped>
  .chat-card {
    max-width: 600px;
    margin: 20px auto;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .user-info {
    margin-bottom: 15px;
  }
  
  .chat-messages {
    max-height: 400px; /* Fixed height for the chat messages */
    overflow-y: auto; /* Enable vertical scrolling */
    margin-bottom: 15px; /* Space between messages and input */
  }
  
  .message {
    display: flex;
    margin: 10px 0;
    align-items: flex-start;
  }
  
  .message-content {
    padding: 10px;
    border-radius: 10px;
    max-width: 75%;
    word-break: break-word;
  }
  
  .my-message {
    justify-content: flex-end;
  }
  
  .my-message .message-content {
    background-color: #d1e7dd;
    align-self: flex-end;
    text-align: right;
  }
  
  .other-message {
    justify-content: flex-start;
  }
  
  .other-message .message-content {
    background-color: #f8d7da;
    align-self: flex-start;
    text-align: left;
  }
  
  .message-input {
    display: flex;
    align-items: center;
  }
  
  .message-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .message-input button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .message-input button:hover {
    background-color: #0056b3;
  }
  </style>