<template>
    <div>
      <div class="container mt-5">
        <h1 class="mb-4">Sample Submission</h1>
        <form @submit.prevent="submitSampleOrder">
          <div class="mb-3">
            <label for="title" class="form-label">Title</label>
            <input type="text" class="form-control" id="title" v-model="title" required>
          </div>
          <div class="mb-3">
            <label for="description" class="form-label">Description</label>
            <textarea class="form-control" id="description" v-model="description" required></textarea>
          </div>
          <div class="mb-3">
            <label class="form-label">Upload Files</label>
            <div class="input-group">
              <span class="input-group-text"><i class="bi bi-upload"></i></span>
              <input type="file" class="form-control" @change="handleFileUpload" multiple>
            </div>
          </div>
          <ul class="list-group mb-3">
            <li class="list-group-item" v-for="file in submissionFiles" :key="file.name">
              <i class="bi bi-file-earmark"></i> {{ file.name }}
              <button type="button" class="btn btn-danger btn-sm float-end" @click="removeFile(file)">Remove</button>
            </li>
          </ul>
          <!-- <div class="mb-3">
            <label for="status" class="form-label">Status</label>
            <textarea class="form-control" id="status" v-model="status" required></textarea>
          </div> -->
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
  
      <!-- <div>
        <ul v-if="liveOrderPackages.length">
          <li v-for="pkg in liveOrderPackages" :key="pkg.id">
            <div>
              <h4>Package Details</h4>
              <p>ID: {{ pkg.id }}</p>
              <p>Name: {{ pkg.name }}</p>
              <p>Price: {{ pkg.price }}</p>
              <p>Status: {{ pkg.status }}</p>
              <p>Delivery Day: {{ pkg.delivery_day }}</p>
              <p>Inclusions: {{ pkg.inclusions }}</p>

              <h4>Live Order Details</h4>
              <p>Live Order ID: {{ pkg.live_order.id }}</p>
              <p>Highest Price: {{ pkg.live_order.highest_price }}</p>
              <p>Total Amount: {{ pkg.live_order.total_amount }}</p>
              <p>Payment Method: {{ pkg.live_order.payment_method }}</p>
              <p>Comments: {{ pkg.live_order.comments }}</p>
              <p>Bayer Id: {{ pkg.live_order.user_id }}</p>
            </div>
          </li>
        </ul>
        <p v-else>No live order packages found.</p>
      </div> -->

      <div class="getSubmissionData">

        <div>
  <div v-if="loading" class="alert alert-info">Loading...</div>
  <div v-if="error" class="alert alert-danger">{{ error }}</div>
  
  <div v-if="!loading && !error" class="row">
    <!-- Bootstrap card for each submission -->
    <div class="col-md-4 mb-4" v-for="submission in submissions" :key="submission.id">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">ID: {{ submission.id }}</h5>
          <p class="card-text">Title: {{ submission.title }}</p>
          <p class="card-text">Title: {{ submission.description }}</p>

          <!-- Display files if they exist -->
          <div v-if="submission.submission_files.length > 0">
            <h6 class="card-subtitle mb-2 text-muted">Files:</h6>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" v-for="(file, index) in submission.submission_files" :key="index">
                <a :href="file" target="_blank">{{ file.split('/').pop() }}</a>
              </li>
            </ul>
          </div>

          <!-- Display status message -->
          <p class="card-text">Status: {{ statusMessages[submission.status] || 'Unknown status' }}</p>

        </div>
      </div>
    </div>
  </div>
</div>


      </div>

    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        title: '',
        status: 1,
        description: '',
        submissionFiles: [], // Initialize submissionFiles as an array
        liveOrderPackages: [], // Array to store the retrieved data
        bayerId: '', // Store the Bayer ID here
        sellerId: '', // Store the seller ID here
        liveOrderID: '', // Store the live order ID here
        refreshInterval: null,
        submissions: [],
        loading: true,
        error: null,
      };
    },
  
    computed: {
      statusMessages() {
        return {
          1: 'Your sample order is pending',
          2: 'Your sample order has improvement',
          3: 'Your sample order is accepted',
        };
      }
    },
  
    methods: {
      async getLiveOrderPackages() {
        try {
          // Retrieve the userId from localStorage
          const userId = localStorage.getItem('id');
  
          if (!userId) {
            throw new Error('User ID not found in local storage');
          }
  
          // Make a GET request to your Laravel backend
          const response = await axios.get(`/live_order_packages_sample/${userId}`);
  
          // Store the data in the liveOrderPackages array
          this.liveOrderPackages = response.data;
          
          // Assuming you want to set the Bayer ID and Live Order ID from the first package
          if (this.liveOrderPackages.length > 0) {
            this.bayerId = this.liveOrderPackages[0].live_order.user_id;
            this.liveOrderID = this.liveOrderPackages[0].live_order.id; // Set the live order ID
            
            // Call fetchSampleSubmissions after setting liveOrderID
            this.fetchSampleSubmissions();
          }
        } catch (error) {
          console.error('Error fetching live order packages:', error);
        }
      },
  
      fetchSampleSubmissions() {
        this.loading = true; // Set loading state to true when starting to fetch
  
        const userId = localStorage.getItem('id');
  
        if (!userId) {
          this.error = 'User ID not found in local storage.';
          this.loading = false;
          return;
        }
  
        // Make sure liveOrderID is available before making the request
        if (!this.liveOrderID) {
          this.error = 'Live Order ID not found.';
          this.loading = false;
          return;
        }
  
        axios.get('/get-seller-sample-submissions', {
          params: { 
            user_id: userId,
            live_order_id: this.liveOrderID // Pass the liveOrderID as a parameter
          }
        })
        .then(response => {
          this.submissions = response.data;
          this.error = null; // Clear any previous error
        })
        .catch(error => {
          this.error = 'There was an error fetching the data.';
          console.error(error);
        })
        .finally(() => {
          this.loading = false; // Set loading state to false when done
        });
      },
  
      startAutoRefresh() {
        this.refreshInterval = setInterval(this.fetchSampleSubmissions, 40000); // Refresh every 5 seconds
      },
  
      stopAutoRefresh() {
        if (this.refreshInterval) {
          clearInterval(this.refreshInterval); // Stop refreshing when component is destroyed
        }
      },
  
      handleFileUpload(event) {
        // Get the selected files from the input
        const selectedFiles = Array.from(event.target.files);
        // Add new files to the existing files
        this.submissionFiles = [...this.submissionFiles, ...selectedFiles];
      },
  
      removeFile(file) {
        // Remove file from the submissionFiles array
        this.submissionFiles = this.submissionFiles.filter(f => f !== file);
      },
  
      submitSampleOrder() {
  const sellerId = localStorage.getItem('id');
  const formData = new FormData();
  formData.append('title', this.title);
  formData.append('description', this.description);
  formData.append('seller_id', sellerId);
  formData.append('bayer_id', this.bayerId);
  formData.append('live_order_id', this.liveOrderID);
  this.submissionFiles.forEach(file => {
    formData.append('submissionFiles[]', file);
  });
  formData.append('status', this.status);

  console.log([...formData]); // Log FormData contents

  axios.post('/submit_sample_order', formData)
    .then(response => {
      console.log('Order Submitted', response.data);
      window.alert('Your order has been submitted successfully.');
      this.title = '';
      this.description = '';
      this.submissionFiles = [];
    })
    .catch(error => {
      console.error('Error submitting order:', error);
      window.alert('There was an error submitting your order. Please try again.');
    });
},
    },
  
    mounted() {
      // Fetch the data when the component is mounted
      this.getLiveOrderPackages();
      this.fetchSampleSubmissions(); // Fetch data when the component is mounted
      this.startAutoRefresh(); // Start auto-refresh
    },
  
    beforeUnmount() {
      this.stopAutoRefresh(); // Clean up the interval when component is unmounted
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
  }
  .input-group-text {
    display: flex;
    align-items: center;
  }
  </style>