<template>
 <footer>

  <div class="row mt-5">
  <!-- Categories Column -->
  <div class="col-md-3">
    <h3>Categories</h3>
    <ul>
      <router-link to="/graphicsdesign" >
      <li>Graphics & Design</li></router-link>
      <router-link to="/digitalmarketing" >
      <li>Digital Marketing</li></router-link>
      <router-link to="/writing" >
      <li>Writing & Translation</li></router-link>
      <router-link to="/video" >
      <li>Video & Animation</li></router-link>
      <router-link to="/music" >
      <li>Music & Audio</li></router-link>
      <router-link to="/programming" >
        <li>Programming & Tech</li></router-link>
    </ul>
  </div>
  <!-- About Column -->
  <div class="col-md-3">
    <h3>About</h3>
    <ul>
      <li>Careers</li>
      <li>Press & News</li>
      <li>Privacy Policy</li>
      <li>Terms of Service</li>
    </ul>
  </div>
  <!-- Community Column -->
  <div class="col-md-3">
    <h3>Community</h3>
    <ul>
      <li>Customer Success Stories</li>
      <li>Community Hub</li>
      <li>Forum</li>
      <li>Events</li>
    </ul>
  </div>
</div>



<div class="container position-relative">
    <!-- Your existing footer content -->
    
    <!-- Logo -->
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="logo">ProLance<b>.</b></div>
      <!-- Social media logos -->
      <div class="social-icons d-flex">
        <a href="#" class="social-icon">
          <!-- Facebook logo -->
          <img src="../assets/social logo/facebook-logo.svg" alt="Facebook" class="social-logo">
        </a>
        <a href="#" class="social-icon">
          <!-- Payoneer logo -->
          <img src="../assets/social logo/payoneer-logo.svg" alt="Payoneer" class="social-logo">
        </a>
        <a href="#" class="social-icon">
          <!-- Instagram logo -->
          <img src="../assets/social logo/instagram-logo.svg" alt="Instagram" class="social-logo">
        </a>
        <a href="#" class="social-icon">
          <!-- LinkedIn logo -->
          <img src="../assets/social logo/linkedin-logo.svg" alt="LinkedIn" class="social-logo">
        </a>
      </div>
    </div>
    
  </div>
      




  
  </footer>
</template>

<script>
export default {
  // Your component logic goes here
}
</script>

<style scoped>




.social-icons {
  display: flex;
  gap: 10px; /* Adjust the gap between icons */
}

.social-logo {
  width: 30px; /* Adjust the width of the social media icons */
  height: auto; /* Maintain aspect ratio */
}

.logo {
  font-size: 40px;
  font-weight: 800;
  color: #3d535f;
  letter-spacing: 3px;
  cursor: pointer;
  text-transform: uppercase;
}

b{
  font-size:70px;
  color:#7f00ff;
  
  
  
  }


  .col-md-3{
  margin: auto;

}


</style>
