<template>
  <div class="create-opportunity-page">
    <h1>Create an Opportunity</h1>
    <form @submit.prevent="submitOpportunity">
      <!-- Opportunity Title -->
      <div class="form-group">
        <label for="opportunity_title">Opportunity Title</label>
        <input type="text" id="opportunity_title" v-model="opportunity_title" required>
      </div>

      <!-- Category Dropdown -->
      <div class="form-group">
        <label for="category">Category</label>
        <select id="category" v-model="selectedCategory" @change="updateSubcategories" required>
          <option value="">Select Category</option>
          <option v-for="category in filteredCategories" :key="category.id" :value="category.id">{{ category.c_title }}</option>
        </select>
      </div>

      <!-- Subcategory Dropdown -->
      <div class="mb-3" v-if="subcategories.length > 0">
        <label for="businessSubCategory" class="form-label">Subcategory:</label>
        <select id="businessSubCategory" class="form-select" v-model="selectedSubCategory" required>
          <option value="" disabled>Select a subcategory</option>
          <option v-for="subcategory in subcategories" :key="subcategory.id" :value="subcategory.id">{{ subcategory.sub_c_title }}</option>
        </select>
      </div>

      <!-- Search Tags -->
      <div class="form-group">
        <label for="tags">Search Tags</label>
        <input type="text" id="tags" v-model="newTag" @keydown.enter.prevent="addTag">
        <div class="tags-container">
          <span v-for="(tag, index) in tags" :key="index" class="tag">
            {{ tag }}
            <button type="button" @click="removeTag(index)">×</button>
          </span>
        </div>
      </div>

      <!-- Submit Button -->
      <button type="submit" v-if="!formSubmitted">Create Opportunity</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      formSubmitted: false,
      opportunity_title: '',
      categories: [],
      filteredCategories: [],
      selectedCategory: '',
      newTag: '',
      tags: [],
      selectedSubCategory: '',
      subcategories: [],
      profession: {} // Added profession to data
    };
  },
  created() {
    if (localStorage.getItem('opportunityCreated') === 'true') {
      this.formSubmitted = true;
    } else {
      this.formSubmitted = false;
    }
    this.fetchCategories();
    this.fetchProfession(); // Fetch or set the profession data
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await axios.get('/getCategories');
        this.categories = response.data;
        this.filterCategoriesByProfession();
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    },

    filterCategoriesByProfession() {
      const professionId = localStorage.getItem('profession_id');
      this.filteredCategories = this.categories.filter(category => category.profession_id == professionId);
    },

    updateSubcategories() {
      if (this.selectedCategory) {
        axios.get(`/getSubcategories/${this.selectedCategory}`)
          .then(response => {
            this.subcategories = response.data;
            this.selectedSubCategory = ''; // Reset selected subcategory
          })
          .catch(error => {
            console.error('Error fetching subcategories:', error);
          });
      } else {
        this.subcategories = [];
        this.selectedSubCategory = '';
      }
    },

    fetchProfession() {
      // Fetch or define the profession data as needed
      const professionId = localStorage.getItem('profession_id');
      const professionTitle = localStorage.getItem('profession_title');
      this.profession = {
        id: professionId,
        title: professionTitle
      };
    },

    // Assuming you have a method to handle category selection
    
// selectCategory(categoryId) {
//   localStorage.setItem('category_id', categoryId);
// },

    addTag() {
      if (this.newTag && this.tags.length < 4) {
        this.tags.push(this.newTag);
        this.newTag = '';
      }
    },

    removeTag(index) {
      this.tags.splice(index, 1);
    },

    async submitOpportunity() {
      try {
        // Get user_id from local storage
        const userId = localStorage.getItem('id');
        
        // Log the token and user ID for debugging purposes
        const token = localStorage.getItem('token');
        console.log('ID:', userId);
        console.log('Token:', token);

        // Fetch seller_id from the query parameters
        const sellerId = this.$route.query.seller_id;
        console.log('Seller ID:', sellerId);

        // Get selected category and subcategory titles
        const selectedCategory = this.categories.find(category => category.id === this.selectedCategory);
        const selectedSubCategory = this.subcategories.find(subcategory => subcategory.id === this.selectedSubCategory);

        // Create the opportunity object with the necessary fields
        const opportunity = {
          user_id: userId,
          seller_id: sellerId,
          opportunity_title: this.opportunity_title,
          category: {
            c_id: this.selectedCategory,
            c_title: selectedCategory ? selectedCategory.c_title : ''
          },
          subCategory: {
            sub_c_id: this.selectedSubCategory,
            sub_c_title: selectedSubCategory ? selectedSubCategory.sub_c_title : ''
          },
          profession: {
            profession_id: this.profession.id,
            profession_title: this.profession.title
          },
          tags: this.tags
        };

        console.log('Opportunity:', opportunity);

        // Send the opportunity data to the backend
        const response = await axios.post('/opportunities', opportunity, {
          headers: {
            Authorization: `Bearer ${token}`, 
            'X-User-ID': userId,
          },
        });

        if (response.status === 201) {
          const opportunityId = response.data.opportunity_id;
          console.log('Opportunity ID:', opportunityId);
          // Store the ID in local storage or use it as needed
          localStorage.setItem('opportunity_id', opportunityId);
        }

        // Set the formSubmitted flag to true
        this.formSubmitted = true;
        // Save a message in local storage indicating the opportunity has been created
        localStorage.setItem('opportunityCreated', 'true');
        console.log('opportunityCreated', 'true');

        console.log('Response:', response.data);

        // Redirect to /package route with the selected category, sub-category, and profession
        this.$router.push({
          path: '/package',
          query: { 
            seller_id: sellerId,
            category_id: this.selectedCategory,
            subCategory_id: this.selectedSubCategory,
            opportunity_id: localStorage.getItem('opportunity_id'),
            profession_id: this.profession.id // Add profession ID to the query parameters
          }
        });
      } catch (error) {
        console.error('Error submitting opportunity:', error);
        // Reset formSubmitted to false if there's an error
        this.formSubmitted = false;
      }
    }
  }
};
</script>



<style scoped>
.create-opportunity-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"], select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.tag {
  background-color: #7f00ff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.tag button {
  background: none;
  border: none;
  color: #fff;
  margin-left: 5px;
  cursor: pointer;
}
</style>
