<template>
    <div class="about">
      
      <!-- Background image -->
      <div class="background-image"></div>
      <div class="overlay-text">Who we are?</div>
      <p class="overlay-paragraph">Welcome to ProLance, your platform for freelancers to showcase their skills and connect with clients. Our mission is to empower freelancers.</p>
    </div>
   <!-- Card section -->
   <div class="card-section">
      <!-- First row -->
      <div class="card-row">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Technology</h5>
            <p class="card-text">Discover the latest technological advancements driving innovation in the freelance industry.</p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Privacy</h5>
            <p class="card-text">Learn about our commitment to privacy and how we protect your data.</p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Customer Support</h5>
            <p class="card-text">Get assistance from our dedicated customer support team available 24/7 to address your concerns.</p>
          </div>
        </div>
      </div>

      <!-- Second row -->
      <div class="card-row">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Design</h5>
            <p class="card-text">Explore the principles of good design and how it can elevate your freelance projects.</p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Marketing</h5>
            <p class="card-text">Learn effective marketing strategies to promote your freelance services and attract clients.</p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Business</h5>
            <p class="card-text">Gain insights into managing your freelance business effectively and maximizing profitability.</p>
          </div>
        </div>
      </div>
    </div>
     <!-- New banner image -->
     <div class="banner-container">
      <img src="@/assets/about/banner.jpg" alt="Banner" class="banner-image">
      <div class="banner-overlay">
        <h2>We're looking for great people who are also great at their jobs.</h2>
        <button class="btn">Learn More</button>
      </div>
    </div>

     <!-- Categories, About, and Community Row -->

 
  
  </template>
  
  <script>
  export default {
    name: 'AboutUs',
  }
  </script>
  
  <style scoped>
  .about {
    height: 100%;
    width: 100%;
    background-color: rgb(7, 7, 7);
  }
  
  
  
  .middle-part {
    display: flex;
    width: 50%;
  }
  
  .right-part {
    width: 20%;
    text-align: center;
    color: white;
    background-color: rgb(255, 0, 0);
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .logo {
    display: flex;
    font-size: 30px;
    font-weight: 800;
    color: #3d535f;
    letter-spacing: 3px;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  b {
    font-size: 30px;
    color: #7f00ff;
  }
  
  .background-image {
    background-image: url('@/assets/about/office.jpg');
    background-size: cover;
    height: calc(100vh - 80px); 
    width: 100%;
  }

  .overlay-text {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: rgb(11, 211, 194);
}

.overlay-paragraph {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: rgb(3, 3, 3);
  text-align: center;
}

.card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.card-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.card {
  width: 30%;
}

.card-body {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #c4d14d;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #007bff;
}

.card-text {
  font-size: 1rem;
  color: #666;
}


.banner-image {
  width: 100%;
  height: auto;
  margin-top: 20px; 
}

.banner-container {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 20px; 
  }

  .banner-overlay {
    position: absolute;
    top: 50%;
    left: 80%;
    transform: translate(-80%, -50%);
    text-align: left;
    color: white;
  }

  .banner-overlay h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .btn {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #7f00ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .social-icons {
  display: flex;
  gap: 10px;
}

.social-logo {
  width: 30px;
  height: auto; 
}




  </style>
  