<template>
    <div class="account-security-page">
      <div class="header">
        <div class="Logo">ProLance<b>.</b></div>
      </div>
      <div class="content">
        <h3>Account Security</h3>
        <form @submit.prevent="finishSetup">
          <div class="form-group">
            <label for="email">Email (Private)</label>
            <input type="email" id="email" v-model="email" required>
            <button type="button" class="verify-button" @click="verifyEmail">{{ emailVerified ? 'Verified' : 'Verify Email' }}</button>
          </div>
          <div class="form-group">
            <label for="phoneNumber">Phone Number (Private)</label>
            <input type="tel" id="phoneNumber" v-model="phoneNumber" @focus="showPhonePopup" readonly required>
          </div>
          <button type="submit" class="finish-button">Finish</button>
        </form>
      </div>
  
      <!-- Phone number modal -->
      <div v-if="phonePopupVisible" class="phone-popup">
        <div class="phone-popup-content">
          <h3>Enter Your Phone Number</h3>
          <div class="form-group">
            <label for="countryCode">Country Code</label>
            <input type="text" id="countryCode" v-model="countryCode" required>
          </div>
          <div class="form-group">
            <label for="popupPhoneNumber">Phone Number</label>
            <input type="tel" id="popupPhoneNumber" v-model="popupPhoneNumber" required>
          </div>
          <button @click="savePhoneNumber">Save</button>
          <button @click="closePhonePopup">Cancel</button>
        </div>
      </div>
  
      <!-- Confirmation modal -->
      <div v-if="confirmationModalVisible" class="confirmation-modal">
        <div class="confirmation-modal-content">
          <h3>Your seller profile is all set!</h3>
          <p>Now go ahead and create your first Opportunity to start selling your services. You can edit your profile information anytime.</p>
          <button @click="proceedToCreateGig">Create Your First Opportunity</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'AccountSecurityPage',
    data() {
      return {
        email: '',
        phoneNumber: '',
        emailVerified: false,
        phonePopupVisible: false,
        confirmationModalVisible: false,
        countryCode: '',
        popupPhoneNumber: '',
      };
    },
    methods: {
      verifyEmail() {
        // Logic to send verification email
        this.emailVerified = true; // Mock verification
      },
      showPhonePopup() {
        this.phonePopupVisible = true;
      },
      closePhonePopup() {
        this.phonePopupVisible = false;
      },
      savePhoneNumber() {
        this.phoneNumber = `+${this.countryCode} ${this.popupPhoneNumber}`;
        this.closePhonePopup();
      },
      finishSetup() {
        // Save the account security info to localStorage or state management
        localStorage.setItem('email', this.email);
        localStorage.setItem('phoneNumber', this.phoneNumber);
        localStorage.setItem('emailVerified', this.emailVerified);
  
        // Show the confirmation modal
        this.confirmationModalVisible = true;
      },
      gatherAllData() {
        
        // Retrieve all data from localStorage
        const professionId = localStorage.getItem('profession_id');
        const professionTitle =localStorage.getItem('profession_title');
        const fullName = localStorage.getItem('fullName');
        const displayName = localStorage.getItem('displayName');
        const description = localStorage.getItem('description');
        const language = localStorage.getItem('language');
        const languageLevel = localStorage.getItem('languageLevel');
        // const profession = localStorage.getItem('profession');
        const skills = localStorage.getItem('skills');
 
  
        // Combine all the data
        return {
          fullName,
          displayName,
          description,
          language,
          languageLevel,
          professionId,
          professionTitle,
          // profession,
          skills,
          email: this.email,
          phoneNumber: this.phoneNumber,
          emailVerified: this.emailVerified,
        };
      },
      async sendDataToBackend(data) {
        const token = localStorage.getItem('token'); // Retrieve token
        const userId = localStorage.getItem('id'); // Retrieve user ID

              console.log('ID:', userId);
      console.log('Token:', token);
        
  
        try {
          const response = await axios.post('/sendsellerdata', data, {
            headers: {
              Authorization: `Bearer ${token}`, 
              'X-User-ID': userId,
            },
          });

          const { seller_id, responseData } = response.data; // Rename 'data' to 'responseData'

// Store seller_id in local storage
localStorage.setItem('seller_id', seller_id);

console.log('Seller ID stored:', seller_id);
console.log('Response data:', responseData);
          console.log('Data sent successfully:', response.data);
        } catch (error) {
          console.error('Error sending data:', error);
        }
      },
      async proceedToCreateGig() {
        // Gather all the data
        const userData = this.gatherAllData();
  
        // Send the data to the backend
        await this.sendDataToBackend(userData);
        const sellerId = localStorage.getItem('seller_id');

        console.log('Seller ID send to opportunity:', sellerId);

  
        // Navigate to the create gig page
        this.$router.push({
            path: '/createopportunity',
            query: { seller_id:sellerId }

        });
      }
    }
  };
  </script>
  
  <style scoped>
  .account-security-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 20px;
  }
  
  .Logo {
    display: flex;
    font-size: 40px;
    font-weight: 800;
    color: #3d535f;
    letter-spacing: 3px;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .Logo b {
    font-size: 50px;
    color: #7f00ff;
  }
  
  .content {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-size: 1em;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .verify-button {
    margin-top: 10px;
    padding: 5px 10px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .verify-button:hover {
    background-color: #0056b3;
  }
  
  .finish-button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 1em;
    color: #fff;
    background-color: #00ff22;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .finish-button:hover {
    background-color: #00b32d;
  }
  
  /* Phone popup styles */
  .phone-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .phone-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 400px;
  }
  
  .phone-popup-content h3 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .phone-popup-content .form-group {
    margin-bottom: 20px;
  }
  
  .phone-popup-content label {
    display: block;
    margin-bottom: 5px;
  }
  
  .phone-popup-content input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .phone-popup-content button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .phone-popup-content button:hover {
    background-color: #0056b3;
  }
  
  /* Confirmation modal styles */
  .confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .confirmation-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    text-align: center;
  }
  
  .confirmation-modal-content h3 {
    margin-bottom: 20px;
  }
  
  .confirmation-modal-content p {
    margin-bottom: 20px;
  }
  
  .confirmation-modal-content button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #00ff22;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .confirmation-modal-content button:hover {
    background-color: #00b32d;
  }
  </style>
  