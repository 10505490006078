<template>
  <div style="width: 100%; height:120dvh">
    <div class="background">
      <div class="content">

          <h1>Find the Best Services</h1>

       
        <!-- Search bar -->
        <div class="searchBar">
          <form class="d-flex" role="search">
            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
            <button class="btn btn-primary" type="submit">Search</button>
          </form>
        </div>



        <!-- <div class="search-bar">
          <input type="text" placeholder="Search...">
          <button>Search</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // Your component logic goes here
}
</script>

<style lang="scss" scoped>
.background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: backgroundAnimation 5s infinite alternate;
}

@keyframes backgroundAnimation {
  0% {
    background-image: url("../assets/slide/img01.jpg");
  }

  50% {
    background-image: url("../assets/slide/img03.jpg");
  }

  100% {
    background-image: url("../assets/slide/img02.jpg");
  }
}

.content {
  height: 100%;
  padding-left: 5%;
  padding-top: 10%;
  text-shadow: 2px 2px 4px #000;

  // display: flex;



}
.searchBar{

  width: 25dvw;
  margin-top: 5%;

}

h1 {
color: rgb(255, 255, 255);
}
// .search-bar input {
//   width: 200px;
//   padding: 5px;
//   margin-right: 10px;
//   border: none;
//   border-radius: 3px;
//   outline: none;
// }

// .search-bar button {
//   padding: 5px 10px;
//   border: none;
//   background-color: #007bff;
//   color: white;
//   border-radius: 3px;
//   cursor: pointer;
// }
</style>
