<template>
    <div class="main">

        <!-- <div class="container position-relative"> -->
            <div class="container position-relative">
      <!-- Row for cards -->
      <div class="row A">
        <!-- First card column -->
        <div class="col-md-3">
          <div class="card mb-3 card-custom">
            <div class="card-body">
              <img src="@/assets/animate icon/money_bag_icon.gif" alt="Money Bag Icon">
              <h5 class="card-title">Stick to your budget.</h5>
              <p class="card-text"> Find the right service for every price point. No hourly rates, just project-based pricing.</p>
             
            </div>
          </div>
        </div>
        <!-- Second card column -->
        <div class="col-md-3">
          <div class="card mb-3 card-custom">
            <div class="card-body">
              <img src="@/assets/animate icon/quality_icon.gif" alt="Quality Icon">
              <h5 class="card-title">Get quality work done quickly</h5>
              <p class="card-text">Hand your project over to a talented freelancer in minutes, get long-lasting results.</p>
       
            </div>
          </div>
        </div>
        <!-- Third card column -->
        <div class="col-md-3">
          <div class="card mb-3 card-custom">
            <div class="card-body">
              <img src="@/assets/animate icon/like_icon.gif" alt="Like Icon">
              <h5 class="card-title">Pay when you're happy</h5>
              <p class="card-text">Upfront quotes mean no surprises. Payments only get released when you approve.</p>
           
            </div>
          </div>
        </div>
        <!-- Fourth card column -->
        <div class="col-md-3">
          <div class="card mb-3 card-custom">
            <div class="card-body">
              <img src="@/assets/animate icon/time_icon.gif" alt="Time Icon">
              <h5 class="card-title">Count on 24/7 support</h5>
              <p class="card-text">Our round-the-clock support team is available to help anytime, anywhere.</p>
            
            </div>
          </div>
        </div>
      </div>
      <!-- Image with overlay text and button -->
      <div class="overlay-text">
        <h2 class="text-white">Discover limitless opportunities and embark on your journey towards success with our comprehensive range of services</h2>
        <div class="d-flex justify-content-start">
          <router-link to="/login"><button class="btn btn-primary">Get Started</button></router-link>
        </div>
      </div>
      <img src="../assets/slide/img04.jpg" class="img-fluid" alt="...">


      <!-- Popular Services -->
      <div class="popular-services">
        <h3>Popular Services</h3>
        <div class="icon-list">
          <div class="icon-item">
            <img src="../assets/icons/graphics_design.svg" alt="Graphics & Design">
            <span>Graphics & Design</span>
          </div>
          <div class="icon-item">
            <img src="../assets/icons/digital_marketing.svg" alt="Digital Marketing">
            <span>Digital Marketing</span>
          </div>
          <div class="icon-item">
            <img src="../assets/icons/writing_translation.svg" alt="Writing & Translation">
            <span>Writing & Translation</span>
          </div>
          <div class="icon-item">
            <img src="../assets/icons/video_animation.svg" alt="Video & Animation">
            <span>Video & Animation</span>
          </div>
          <div class="icon-item">
            <img src="../assets/icons/music_audio.svg" alt="Music & Audio">
            <span>Music & Audio</span>
          </div>
          <div class="icon-item">
            <img src="../assets/icons/photography.svg" alt="Photography">
            <span>Photography</span>
          </div>
        </div>
      </div>
      
    </div>

    <div class="row">
  <div class="col-md-6">
    <img src="../assets/slide/img05.jpg" class="img-fluid custom-image" alt="Image">
  </div>
  <div class="col-md-6">
    <div class="definition">
      <p >"Business is the art of creating value by solving problems for others, fueled by passion, innovation, and a relentless pursuit of excellence."</p>
      <h3 class="author">Richard Branson</h3>
    </div>
  </div>
</div>
 <!-- Categories, About, and Community Row -->



    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

.main{
    width: 100%;
    height: 100%;
    
}

.A{
    padding: 2.5%;

}

.overlay-text {
  position: absolute;
  top: 60%;
  left: 50px;
  transform: translateY(-50%);
  z-index: 1;
  width:50%
}

.overlay-text h2 {
  margin-bottom: 40px;
}

.popular-services {
  margin-top: 30px;
}

.icon-list {
  display: flex;
  justify-content: space-between;
}

.icon-item {
  text-align: center;
}

.icon-item img {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
}

.icon-item span {
  display: block;
}



.author{
  color: #ff0000; 
  font-family: cursive;

}

.card-custom {
  border: 2px solid #ff0000; /* Outline color */
  height: 300px;
  width: 230px;
}



.custom-image {
  max-width: 50%; /* Adjust the maximum width as needed */
  height: auto; 
  margin-top: 30px;
  margin-left: 60px;/* Maintain aspect ratio */
}
.definition{
  color: #10df55; 
  font-family: cursive; 
  font-size: 30px;
  margin-top: 80px;
  margin-right: 10px;

}


// .card-title-custom {
//   color: #ff0000; /* Text color */
// }


</style>