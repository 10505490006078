<template>
    <div>
      <div>
        <LoginHeader
        :isLoggedIn="isLoggedIn"
        :firstName="firstName"
        :profilePhoto="profilePhoto"
        :role="role"
       
        @update:isLoggedIn="handleLoginState" />
      </div>
  
      <div>
        <SellerDashboard />
      </div>
      <div>
        <FooterPart />
      </div>
    
  
    </div>
  </template>
  
  <script>

  import FooterPart from '@/components/FooterPart.vue';
  import SellerDashboard from '@/components/Dashboard/SellerDashboard.vue';
import LoginHeader from '@/components/categories/LoginHeader.vue';
 
  
  
    export default {

      
  
      components:{
        LoginHeader,
        SellerDashboard,
      FooterPart
      
  },

  data() {
    return {
      isLoggedIn: false,
      firstName: '',
      profilePhoto: '',
      role: '',
       
    };
  },

  methods: {
    handleLoginState(value) {
      this.isLoggedIn = value;
      if (!value) {
        this.firstName = '';
        this.profilePhoto = '';
        this.role = '';
        this.currentUserId = null;
      } else {
        this.firstName = localStorage.getItem('firstName') || '';
        this.profilePhoto = localStorage.getItem('profilePhoto') || '';
        this.role = localStorage.getItem('role') || '';
      
      }
    }
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token) {
      this.isLoggedIn = true;
      this.firstName = localStorage.getItem('firstName') || '';
      this.profilePhoto = localStorage.getItem('profilePhoto') || '';
      this.role = localStorage.getItem('role') || '';
     
    }
  }
};
      
    
  </script>
  
  <style lang="scss" scoped>
  
  
  </style>