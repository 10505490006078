<template>
  <div>

    <div>
      <FrontHeader/>
    </div>

    <div>
      <AboutUs />
    </div>
    <div>
      <FooterPart />
    </div>

  </div>
</template>
k
<script>
import FooterPart from '@/components/FooterPart.vue';
import FrontHeader from '@/components/FrontHeader.vue';
import AboutUs from '@/components/about/AboutUs.vue';
  export default {
    

    components:{
    AboutUs,
    FooterPart,
    FrontHeader
}
    
  }
</script>

<style lang="scss" scoped>


</style>