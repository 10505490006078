<template>
    <div style="background-color: lightblue;">
      <div class="container mt-5">
        <h1 class="mb-4">Sample Submission</h1>
        <form @submit.prevent="submitSampleOrder">
    <div>
      <div v-for="order in liveOrders" :key="order.id" @click="selectLiveOrder(order.id)" class="card mb-3">
        <div v-if="liveOrderId === order.id" class="card-body">
          <div class="mb-3">
            <label for="userSelect" class="form-label">Select User</label>
            <select id="userSelect" v-model="selectedUserId" class="form-select">
  <option v-for="orderPackage in order.live_order_packages" :key="orderPackage.id" :value="orderPackage.user_id">
    {{ orderPackage.user_id }}
  </option>
</select>

          </div>
          <ul class="list-group mt-3">
            <!-- You can add list items here if needed -->
          </ul>
        </div>
      </div>
    </div>

          <div class="mb-3">
            <label for="title" class="form-label">Title</label>
            <input type="text" class="form-control" id="title" v-model="title" required>
          </div>
          <div class="mb-3">
            <label for="description" class="form-label">Description</label>
            <textarea class="form-control" id="description" v-model="description" required></textarea>
          </div>
          <div class="mb-3">
            <label class="form-label">Upload Files</label>
            <div class="input-group">
              <span class="input-group-text"><i class="bi bi-upload"></i></span>
              <input type="file" class="form-control" @change="handleFileUpload" multiple>
            </div>
          </div>
          <ul class="list-group mb-3">
            <li class="list-group-item" v-for="file in submissionFiles" :key="file.name">
              <i class="bi bi-file-earmark"></i> {{ file.name }}
              <button type="button" class="btn btn-danger btn-sm float-end" @click="removeFile(file)">Remove</button>
            </li>
          </ul>
<div class="statusClass">
    <div class="statusClass">
  <!-- Checkbox for Option 1 -->
  <div class="form-check">
    <input 
      class="form-check-input" 
      type="checkbox" 
      id="flexCheckOption1" 
      :checked="status === 2" 
      @change="setStatus(2)"
    >
    <label class="form-check-label" for="flexCheckOption1">
      Not Confirm
    </label>
  </div>

  <!-- Checkbox for Option 2 -->
  <div class="form-check">
    <input 
      class="form-check-input" 
      type="checkbox" 
      id="flexCheckOption2" 
      :checked="status === 3" 
      @change="setStatus(3)"
    >
    <label class="form-check-label" for="flexCheckOption2">
      Confirm
    </label>
  </div>
</div>

    
</div>


          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
  
      <!-- show and hidden div, none for hidde, block for view -->
      <div style="display:none;" > 
        <!-- <div style="display:block;" >  -->
        <h1>Live Orders</h1>
        <ul>
          <li v-for="order in liveOrders" :key="order.id" @click="selectLiveOrder(order.id)">
            <p>Live Order ID: {{ order.id }}</p>
            <div v-if="liveOrderId === order.id">
              <h2>Packages for Live Order ID: {{ order.id }}</h2>
              <ul>
                <li v-for="orderPackage in order.live_order_packages" :key="orderPackage.id">
                  <p>Package ID: {{ orderPackage.id }}</p>
                  <p>User ID (Seller ID): {{ orderPackage.user_id }}</p>
                  <p>Package Title: {{ orderPackage.title }}</p>
                  <p>Package Description: {{ orderPackage.description }}</p>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
  
      <div v-if="loading" class="alert alert-info">Loading...</div>
      <div v-if="error" class="alert alert-danger">{{ error }}</div>
  
      <!-- <div v-if="!loading && !error">
        <div class="row">
          <div class="col-md-4 mb-4" v-for="submission in submissions" :key="submission.id">
            <div class="card">
              <div class="card-body">
                <p class="card-text">Seller ID: {{ submission.seller_id }}</p>
                <h5 class="card-title">ID: {{ submission.id }}</h5>
                <p class="card-text">Title: {{ submission.title }}</p>
                <p class="card-text">Description: {{ submission.description }}</p>
                <div v-if="submission.submission_files.length">
                  <h6 class="card-subtitle mb-2 text-muted">Files:</h6>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item" v-for="(file, index) in submission.submission_files" :key="index">
                      <a :href="file" target="_blank">{{ file.split('/').pop() }}</a>
                    </li>
                  </ul>
                </div>
                <p class="card-text">Status: {{ statusMessages[submission.status] || 'Unknown status' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div v-if="!loading && !error">
    <div class="row">
      <div class="col-md-4 mb-4" v-for="(submission, sellerId) in latestSubmissions" :key="sellerId">
        <div class="card">
          <div class="card-body">
            <p class="card-text">Seller ID: {{ sellerId }}</p>
            <h5 class="card-title">ID: {{ submission.id }}</h5>
            <p class="card-text">Title: {{ submission.title }}</p>
            <p class="card-text">Description: {{ submission.description }}</p>
            <div v-if="submission.submission_files.length">
              <h6 class="card-subtitle mb-2 text-muted">Files:</h6>
              <ul class="list-group list-group-flush">
                <li class="list-group-item" v-for="(file, index) in submission.submission_files" :key="index">
                  <a :href="file" target="_blank">{{ file.split('/').pop() }}</a>
                </li>
              </ul>
            </div>
            <p class="card-text">Status: {{ statusMessages[submission.status] || 'Unknown status' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        title: '',
        description: '',
        submissionFiles: [],
        liveOrders: [],
        error: null,
        liveOrderId: null,
        submissions: [],
        loading: false,
        selectedUserId: null, // Add this to hold the selected user ID
        status: 2, // Default status value
      };
    },
  
    computed: {
      statusMessages() {
        return {
          1: 'sample order is pending',
          2: 'I needs improvement',
          3: 'I accepted this sample order',
        };
      },
      sellerId() {
      return this.selectedUserId; // Compute sellerId from selectedUserId
    },

    latestSubmissions() {
      const groupedBySellerId = this.submissions.reduce((acc, submission) => {
        const existing = acc[submission.seller_id];
        if (!existing || new Date(submission.created_at) > new Date(existing.created_at)) {
          acc[submission.seller_id] = submission;
        }
        return acc;
      }, {});

      return groupedBySellerId;
    }
    },
  
    beforeUnmount() { // Changed from beforeDestroy to beforeUnmount
    clearInterval(this.autoRefreshInterval); // Clean up the interval on component destroy
  },
    methods: {
        setStatus(value) {
      this.status = value;
    },

      async fetchLiveOrder() {
        const userId = localStorage.getItem('id');
        try {
          const response = await axios.get(`/live_order_sample_bayer/${userId}`);
          this.liveOrders = response.data;
          if (this.liveOrders.length > 0) {
            this.liveOrderId = this.liveOrders[0].id;
            // Automatically set sellerId to the user_id of the first package in the first live order
            // if (this.liveOrders[0].live_order_packages.length > 0) {
            //   this.sellerId = this.liveOrders[0].live_order_packages[0].user_id;
            // }
          }
        } catch (error) {
          console.error('Error fetching live orders:', error);
          this.error = 'Failed to fetch live orders.';
        }
      },
  
      selectLiveOrder(orderId) {
        this.liveOrderId = orderId;
      },
  
      async fetchSampleSubmissions() {
      if (!this.liveOrderId) {
        this.error = 'Live Order ID not found.';
        return;
      }
  
      const userId = localStorage.getItem('id');
      this.loading = true;
  
      try {
        const response = await axios.get('/get-bayer-sample-submissions', {
          params: {
            user_id: userId,
            live_order_id: this.liveOrderId,
          },
        });
        this.submissions = response.data;
        this.error = null;
      } catch (error) {
        console.error('Error fetching sample submissions:', error);
        this.error = 'Failed to fetch sample submissions.';
      } finally {
        this.loading = false;
      }
    },
    startAutoRefresh() {
      this.autoRefreshInterval = setInterval(() => {
        this.fetchSampleSubmissions();
      }, 30000); // Refresh every 30 seconds (adjust as needed)
    },
    
  
      handleFileUpload(event) {
        const selectedFiles = Array.from(event.target.files);
        this.submissionFiles = [...this.submissionFiles, ...selectedFiles];
      },
  
      removeFile(file) {
        this.submissionFiles = this.submissionFiles.filter(f => f !== file);
      },
  
      async submitSampleOrder() {
        const bayerId = localStorage.getItem('id');
        const formData = new FormData();
        formData.append('title', this.title);
        formData.append('description', this.description);
        formData.append('bayer_id', bayerId);
        // formData.append('seller_id', this.sellerId); // Use the automatically set sellerId
        formData.append('seller_id', this.sellerId); // Use computed sellerId
        formData.append('status', this.status); // Corrected this line
        formData.append('live_order_id', this.liveOrderId);
  
        this.submissionFiles.forEach(file => {
          formData.append('submissionFiles[]', file);
        });
  
        try {
          const response = await axios.post('/submit_sample_order', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          console.log('Order Submitted', response.data);
          window.alert('Your order has been submitted successfully.');
          this.title = '';
          this.description = '';
          this.submissionFiles = [];
        } catch (error) {
          console.error('Error submitting order:', error);
          window.alert('There was an error submitting your order. Please try again.');
        }
      }
    },
    
  
    watch: {
      liveOrderId(newId) {
        if (newId) {
          this.fetchSampleSubmissions();
        }
      }
    },

  
    mounted() {
      this.fetchLiveOrder();
      this.startAutoRefresh(); // Start auto-refresh
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
  }
  .input-group-text {
    display: flex;
    align-items: center;
  }
  </style>