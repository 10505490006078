<template>
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <div class="card shadow-sm">
            <div class="card-body">
              <h5 class="card-title text-center">
                <i class="bi bi-person-circle"></i> User ID: {{ userId }}
              </h5>
              <div v-if="payments && payments.length">
                <h6 class="card-subtitle mb-2 text-muted text-center">Payment Details</h6>
                <ul class="list-group list-group-flush">
                  <li v-for="payment in payments" :key="payment.id" class="list-group-item">
                    <p>
                      <i class="bi bi-receipt"></i> Order ID: {{ payment.live_order_id }}
                    </p>
                    <p>
                      <i class="bi bi-person"></i> Buyer ID: {{ payment.buyer_id }}
                    </p>
                    <p>
                      <i class="bi bi-person-check"></i> Seller ID: {{ payment.seller_id }}
                    </p>
                    <p>
                      <i class="bi bi-cash-stack"></i> Amount: ${{ payment.amount }}
                    </p>
                    <p>
                      <i class="bi bi-cash"></i> Highest Price: ${{ payment.highest_price }}
                    </p>
                    <p>
                      <i class="bi bi-percent"></i> Service Charge: ${{ payment.service_charge }}
                    </p>
                    <p>
                      <i class="bi bi-wallet2"></i> Total Amount: ${{ payment.total_amount }}
                    </p>
                    <p>
                      <i class="bi bi-calendar"></i> Created At: {{ new Date(payment.created_at).toLocaleString() }}
                    </p>
                    <p>
                      <i class="bi bi-calendar-check"></i> Updated At: {{ new Date(payment.updated_at).toLocaleString() }}
                    </p>
                  </li>
                </ul>
              </div>
              <div v-else class="alert alert-warning mt-3 text-center" role="alert">
                No payment details available.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        userId: null,
        payments: [],
      };
    },
    mounted() {
      this.userId = localStorage.getItem('id');
      if (this.userId) {
        this.getPaymentData();
      }
    },
    methods: {
      async getPaymentData() {
        try {
          const response = await axios.post('/get-payment-data', { userId: this.userId });
          this.payments = response.data;
        } catch (error) {
          console.error('Error fetching payment data:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any additional custom styles here */
  </style>
  