<template>
  <div>
    <div>
    <div>
      <LoginHeader
        :isLoggedIn="isLoggedIn"
        :firstName="firstName"
        :profilePhoto="profilePhoto"
        :role="role"
        @update:isLoggedIn="handleLoginState"
      />
    </div>

    <div class="welcome-section">
      <img src="@/assets/about/buyer.jpg" alt="Welcome Image" class="welcome-image"/>
      <div class="welcome-text">
        <h1>Welcome, {{ firstName }}</h1>
      </div>
    </div>

    <!-- Main Content -->
    <div v-if="isLoggedIn" class="main-content">

      
      <!-- Messaging Section -->
      <div class="messaging-section">
        <h3>Messages</h3>
        <!-- <div class="inbox"> -->
          <BuyerChat/>
          <!-- <div v-for="(message, index) in messages" :key="index" class="message-item">
            <strong>{{ message.senderName }}</strong>: {{ message.content }}
          </div> -->
        <!-- </div> -->
        <!-- <textarea v-model="newMessage" placeholder="Type your message" class="message-input"></textarea>
        <button @click="sendMessage" class="send-button">Send Message</button> -->
      </div>

      <!-- Orders Section -->
      <div class="orders-section">
        <h3>Orders</h3>
        <BuyerOrder/>
        <div>
          <OrderTest/>
        </div>
        <div class="order-list">
          <div v-for="(order, index) in orders" :key="index" class="order-item">
            <div>
              <strong>Order #{{ order.id }}</strong>
              <p>{{ order.description }}</p>
            </div>
            <div>Status: {{ order.status }}</div>
          </div>
        </div>
      </div>
    </div>

   
  </div>
  <div>
      <FooterPart/>
    </div>
</div>
</template>

<script>
import FooterPart from '@/components/FooterPart.vue';
import LoginHeader from '@/components/categories/LoginHeader.vue';
import BuyerChat from '@/components/Dashboard/ChatPage/BuyerChat.vue';
import BuyerOrder from '@/components/Dashboard/BuyerOrder/BuyerOrder.vue';
import OrderTest from '@/components/Dashboard/BuyerOrder/OrderTest.vue';

export default {
  components: {
    LoginHeader,
    BuyerChat,
    BuyerOrder,
    OrderTest,
    FooterPart,
  },
  data() {
    return {
      isLoggedIn: false,
      firstName: '',
      profilePhoto: '',
      role: '',
      messages: [], // Array to store messages
      newMessage: '', // New message input
      receiverId: '', // Placeholder for the receiver ID, adjust as needed
      orders: [] // Array to store orders
    };
  },
  computed: {
    user() {
      // Retrieve data from localStorage
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const first_name = localStorage.getItem('firstName');
      const profilePhoto = localStorage.getItem('profilePhoto');
      
      return {
        token,
        userId,
        first_name,
        profilePhoto,
      };
    },
  },
  methods: {
    async fetchMessages() {
      try {
        const response = await fetch(`/api/messages?userId=${this.user.userId}`); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch messages');
        }
        const data = await response.json();
        this.messages = data.messages.map(message => ({
          senderName: message.senderName, // Adjust based on your API response structure
          content: message.content
        }));
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },
    async sendMessage() {
      try {
        const response = await fetch('/api/messages/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.user.token}`
          },
          body: JSON.stringify({
            receiver_id: this.receiverId, // Adjust as needed
            message: this.newMessage,
          }),
        });
        if (!response.ok) {
          throw new Error('Failed to send message');
        }
        this.newMessage = ''; // Clear input after sending
        await this.fetchMessages(); // Refresh messages after sending
      } catch (error) {
        console.error('Error sending message:', error);
      }
    },
    async fetchOrders() {
      try {
        const response = await fetch(`/api/orders?userId=${this.user.userId}`); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch orders');
        }
        const data = await response.json();
        this.orders = data.orders;
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    },
    handleLoginState(value) {
      this.isLoggedIn = value;
      if (!value) {
        this.firstName = '';
        this.profilePhoto = '';
        this.role = '';
      } else {
        this.firstName = localStorage.getItem('firstName') || '';
        this.profilePhoto = localStorage.getItem('profilePhoto') || '';
        this.role = localStorage.getItem('role') || '';
      }
    }
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token) {
      this.isLoggedIn = true;
      this.firstName = localStorage.getItem('firstName') || '';
      this.profilePhoto = localStorage.getItem('profilePhoto') || '';
      this.role = localStorage.getItem('role') || '';
      this.fetchMessages();
      this.fetchOrders();
    }
  }
};
</script>

<style scoped>
.welcome-section {
  position: relative;
  text-align: right;
  margin-top: 20px;
}

.welcome-image {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
}

.welcome-text {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Optional: To make the text more readable */
  padding: 10px;
  border-radius: 5px;
}

.welcome-text h1 {
  margin: 0;
  font-size: 2em;
  color: #fff;
  font-family: 'Poppins', sans-serif;
}

.main-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

.messaging-section, .orders-section {
  flex: 1;
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  background-color: #f9f9f9;
}

.inbox, .order-list {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.message-item, .order-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.message-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.send-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}
</style>
