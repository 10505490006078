<template>
  <div class="opportunity-packages-page">
    <h1>Create Packages for Your Opportunity</h1>
    <h2>opp id :{{ opportunityId }}</h2>
    <form @submit.prevent="savePackages">
      <div class="package" v-for="(packageItem, index) in packages" :key="index">
        <h2>Package {{ index + 1 }}</h2>
        <div class="form-group">
          <label :for="'name-' + index">Package Name</label>
          <input type="text" :id="'name-' + index" v-model="packageItem.name" required>
        </div>
        <div class="form-group">
          <label :for="'deliveryday-' + index">Delivery Time</label>
          <select :id="'deliveryday-' + index" v-model="packageItem.deliveryday" required>
            <option v-for="time in deliverydays" :key="time" :value="time">{{ time }} day(s)</option>
          </select>
        </div>
        <div class="form-group">
          <label :for="'revisions-' + index">Revisions</label>
          <select :id="'revisions-' + index" v-model="packageItem.revisions" required>
            <option v-for="revision in revisions" :key="revision" :value="revision">{{ revision }}</option>
          </select>
        </div>

        <div class="form-group">
          <label>What’s Included</label>
          <div v-for="(option, optIndex) in deliveryOptions" :key="optIndex" class="checkbox">
            <input type="checkbox"
                   :id="'option-' + index + '-' + optIndex"
                   :value="option"
                   v-model="packageItem.inclusions">
            <label :for="'option-' + index + '-' + optIndex">{{ option }}</label>
          </div>
        </div>

        <div class="form-group">
          <label :for="'price-' + index">Price ($)</label>
          <input type="number" :id="'price-' + index" v-model.number="packageItem.price" required>
        </div>
      </div>

      <button type="button" @click="addPackage" :disabled="packages.length >= 3">Add Another Package</button>
      <button type="submit">Save Packages</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    opportunityId: {
      type: String,
      required: true
    },
    opportunityData: {
      type: Object,
      required: true
    },
    sellerId: {
      type: Number,
      required: true
    },
    categoryId: {
      type: Number,
      required: true
    },
    subCategoryId: {
      type: Number,
      required: true
    },
    professionId: { // Added professionId as a prop
      type: String,
      required: true
    }
  },
  data() {
    return {
      deliverydays: [1, 2, 3],
      revisions: ['No Revisions', '1 Revision', '2 Revisions', '3 Revisions', 'Unlimited Revisions'],
      deliveryOptions: [], // Will be populated based on the selected category/subcategory
      packages: [
        {
          name: '',
          deliveryday: 1,
          revisions: 'No Revisions',
          inclusions: [],
          price: 0
        }
      ]
    };
  },
  mounted() {
    this.loadDeliveryOptions();
    console.log('Mounted - Seller ID:', this.sellerId);
    console.log('Mounted - Category:', this.categoryId);
    console.log('Mounted - Sub-Category:', this.subCategoryId);
    console.log('Mounted - Profession ID:', this.professionId); // Log profession ID
  },
  methods: {
    async loadDeliveryOptions() {
      const categoryId = this.categoryId;
      const subCategoryId = this.subCategoryId;

      console.log('LoadDeliveryOptions - Category ID:', categoryId);
      console.log('LoadDeliveryOptions - SubCategory ID:', subCategoryId);
      
      if (categoryId) {
        try {
          const response = await axios.get(`/file-formats/${categoryId}`);
          this.deliveryOptions = response.data.map(format => format.file_format);
          console.log('Delivery Options after loading:', this.deliveryOptions);
        } catch (error) {
          console.error('Error fetching file formats:', error);
        }
      } else {
        console.log('Category ID is not defined');
      }
    },
    addPackage() {
      if (this.packages.length < 3) {
        this.packages.push({
          name: '',
          deliveryday: 1,
          revisions: 'No Revisions',
          inclusions: [],
          price: 0
        });
      }
    },
    async savePackages() {
      try {
        const userId = localStorage.getItem('id');
        const token = localStorage.getItem('token');

        const data = {
          user_id: userId,
          opportunity_id: this.opportunityId,
          seller_id: this.sellerId,
          category_id: this.categoryId,
          subCategory_id: this.subCategoryId,
          profession_id: this.professionId ,
          packages: this.packages
        };

        const response = await axios.post('/packages', data, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log('Packages saved successfully:', response.data);
        alert('Packages saved successfully!');

        this.$router.push({
          path: '/imagegallery',
          query: { 
            seller_id: this.sellerId,
            category: this.categoryId,
            subCategory: this.subCategoryId,
            opportunity_id: this.opportunityId,
            profession_id: this.professionId // Include profession ID in route push
          }
        });
      } catch (error) {
        console.error('Error saving packages:', error);
        alert('Failed to save packages.');
      }
    }
  },
  created() {
    this.loadDeliveryOptions(); // Call the method to load options when the component is created
    console.log('Opportunity ID:', this.opportunityId);
    console.log('Created - Profession ID:', this.professionId); // Log profession ID
  }
};
</script>



<style scoped>
.opportunity-packages-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.package {
  margin-bottom: 40px;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="number"],
select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox input {
  margin-right: 10px;
}

button[type="button"] {
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  background-color: #7f00ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

button:hover {
  background-color: #5700b3;
}
</style>
