<template>
    <div class="get-started-page">
      <div class="content">
        <div class="overview-section">
          <!-- <h2>Welcome, {{ firstName }}</h2> -->
          <h3>Ready to start selling on ProLance? Here’s what you need to know:</h3>
          <div class="overview-content">
            <h4>Build a standout profile</h4>
            <p>Highlight your skills and experiences to attract potential clients.</p>
            <h4>Set up your professional profile</h4>
            <p>Add your profile photo, a brief description, and your professional details.</p>
            <h4>Create your first service offer</h4>
            <p>List the services you provide and start reaching out to clients immediately.</p>
          </div>
          <button class="continue-button" @click="continueToNextStep">Continue</button>
        </div>
        <div class="video-section">
          <video controls class="intro-video">
            <source src="@/assets/seller/intro.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OverView',
    data() {
      return {
        firstName: localStorage.getItem('firstName') || 'User',
      };
    },
    computed: {
    user() {
      // Retrieve data from localStorage
      const token = localStorage.getItem('token');
      const id = localStorage.getItem('id');
      const first_name = localStorage.getItem('firstName');
      const profile_photo = localStorage.getItem('profilePhoto');
      
      return {
        token,
        id,
        first_name,
        profile_photo,
      };
    },
  },
    methods: {
      continueToNextStep() {
        this.$router.push('/sellerpath');
      }
    }
  };
  </script>
  
  <style scoped>
  .get-started-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .content {
    display: flex;
    width: 100%;
    max-width: 1200px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .overview-section {
    flex: 1;
    padding: 20px;
  }
  
  .video-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
  }
  
  .intro-video {
    width: 100%;
    height: auto;
    max-height: 400px;
  }
  
  h2 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .overview-content {
    margin-bottom: 20px;
  }
  
  h4 {
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  
  p {
    font-size: 1em;
    margin-bottom: 10px;
  }
  
  .continue-button {
    display: block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #00ff22;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .continue-button:hover {
    background-color: #00b32d;
  }
  </style>
  