<template>
    <div class="personal-info-page">
      <div class="header">
        <div class="Logo">ProLance<b>.</b></div>
      </div>
      <div class="content">
        <h3>Personal Information</h3>
        <form @submit.prevent="continueToProfessionalInfo">
          <div class="form-group">
            <label for="fullName">Full Name (Private)</label>
            <input type="text" id="fullName" v-model="fullName" required>
          </div>
          <div class="form-group">
            <label for="displayName">Display Name</label>
            <input type="text" id="displayName" v-model="displayName" required>
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <textarea id="description" v-model="description" required></textarea>
          </div>
          <div class="form-group">
            <label for="language">Language</label>
            <select id="language" v-model="language" required>
              <option value="">Select Language</option>
              <option value="english">English</option>
              <option value="spanish">Spanish</option>
              <option value="french">French</option>
             
            </select>
          </div>
          <div class="form-group">
            <label for="languageLevel">Language Level</label>
            <select id="languageLevel" v-model="languageLevel" required>
              <option value="">Select Level</option>
              <option value="beginner">Beginner</option>
              <option value="intermediate">Intermediate</option>
              <option value="advanced">Advanced</option>
              <!-- Add more levels as needed -->
            </select>
          </div>
          <button type="submit" class="continue-button">Continue</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PersonalInfoPage',
    data() {
      return {
        fullName: '',
        displayName: '',
        description: '',
        language: '',
        languageLevel: '',
      };
    },
    methods: {
      
      continueToProfessionalInfo()  { 
      //   const id = localStorage.getItem('id');
      // const token = localStorage.getItem('token');

      // Log id and token to console
      // console.log('ID:', id);
      // console.log('Token:', token);

        // Save the personal info to localStorage or state management
        localStorage.setItem('fullName', this.fullName);
        localStorage.setItem('displayName', this.displayName);
        localStorage.setItem('description', this.description);
        localStorage.setItem('language', this.language);
        localStorage.setItem('languageLevel', this.languageLevel);
  
        // Log the data to the console
        console.log('Full Name:', this.fullName);
        console.log('Display Name:', this.displayName);
        console.log('Description:', this.description);
        console.log('Language:', this.language);
        console.log('Language Level:', this.languageLevel);

        // Navigate to the professional info page with the data
        this.$router.push({
          path: '/professional-info',
          query: {
            fullName: this.fullName,
            displayName: this.displayName,
            description: this.description,
            language: this.language,
            languageLevel: this.languageLevel,
            // id: id, // Pass id and token as query parameters if needed
            // token: token,
          }
        });
      }
    }
  };
</script>
  
  <style scoped>
  .personal-info-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 20px;
  }
  
  .Logo {
    display: flex;
    font-size: 40px;
    font-weight: 800;
    color: #3d535f;
    letter-spacing: 3px;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .Logo b {
    font-size: 50px;
    color: #7f00ff;
  }
  
  .content {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-size: 1em;
    margin-bottom: 5px;
  }
  
  input,
  textarea,
  select {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea {
    height: 100px;
  }
  
  .continue-button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 1em;
    color: #fff;
    background-color: #00ff22;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .continue-button:hover {
    background-color: #00b32d;
  }
  </style>
  