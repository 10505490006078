<template>
  <div class="container mt-5">
    <div class="MainClass">

      <div class="firstClass">
      <!-- Your Package Section -->
      <div class="carSection carSectionA mb-5">
        <h3 class="mb-4 text-primary">Your Package</h3>
        <p class="mb-4"><strong>Your Selected Package:</strong> {{ userId }}</p>
        <div class="d-flex flex-wrap justify-content-start gap-4">
          <div v-for="myorder in myorders" :key="myorder.live_order_id" class="flex-item">
            <div class="card h-100 shadow-sm">
              <div class="card-body">
                <p class="card-text"><strong>Name:</strong> {{ myorder.name }}</p>
                <p class="card-text"><strong>Delivery Day:</strong> {{ myorder.delivery_day }}</p>
                <p class="card-text"><strong>Revisions:</strong> {{ myorder.revisions }}</p>
                <p class="card-text"><strong>Inclusions:</strong> {{ myorder.inclusions }}</p>
                <p class="card-text"><strong>Price:</strong> ${{ myorder.price }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Live Ordered Package Section -->
      <div class="carSection carSectionB mb-5">
        <h3 class="mb-4 text-success">Ordered Package</h3>
        <p class="mb-4"><strong>Selected Order Package</strong></p>
        <div class="d-flex flex-wrap justify-content-start gap-4">
          <div v-for="allorder in allorders" :key="allorder.live_order_id" class="flex-item">
            <div class="card h-100 shadow-sm">
              <div class="card-body">
                <h5 class="card-title">Order #{{ allorder.live_order_id }}</h5>
                <p class="card-text"><strong>Name:</strong> {{ allorder.name }}</p>
                <p class="card-text"><strong>Delivery Day:</strong> {{ allorder.delivery_day }}</p>
                <p class="card-text"><strong>Revisions:</strong> {{ allorder.revisions }}</p>
                <p class="card-text"><strong>Inclusions:</strong> {{ allorder.inclusions }}</p>
                <p class="card-text"><strong>Price:</strong> ${{ allorder.price }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>


          <!-- <div class="carSection">
    <h1 class="mb-4">Show Package Details</h1>
    <p class="mb-4"><strong>Required Package Details</strong></p>
      <div class="cardOutline" v-for="packageDetail in packageDetails" :key="packageDetail.id">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Package {{ packageDetail.name }}</h5>
            <p class="card-text"><strong>Opportunity ID:</strong> {{ packageDetail.opportunity_id }}</p>
            <p class="card-text"><strong>Seller ID:</strong> {{ packageDetail.seller_id }}</p>
            <p class="card-text"><strong>Category ID:</strong> {{ packageDetail.category_id }}</p>
            <p class="card-text"><strong>Sub-Category ID:</strong> {{ packageDetail.subCategory_id }}</p>
            <p class="card-text"><strong>Profession ID:</strong> {{ packageDetail.profession_id }}</p>
            <p class="card-text"><strong>Name:</strong> {{ packageDetail.name }}</p>
            <p class="card-text"><strong>Delivery Day:</strong> {{ packageDetail.delivery_day }}</p>
            <p class="card-text"><strong>Revisions:</strong> {{ packageDetail.revisions }}</p>
            <p class="card-text"><strong>Inclusions:</strong> {{ packageDetail.inclusions }}</p>
            <p class="card-text"><strong>Price:</strong> ${{ packageDetail.price }}</p>
          </div>
        </div>
      </div>
  </div>
          -->

      <!-- Request Details Section -->
      <div class="carSection">
        <h1 class="mb-4 text-info">Request Details</h1>
        <p class="mb-4"><strong>Required Package Details:</strong></p>
        <div class="d-flex flex-wrap justify-content-start gap-4">
          <div v-for="bayerOrderDetail in bayerOrderDetails" :key="bayerOrderDetail.id" class="flex-item">
            <div class="card h-100 shadow-sm">
              <div class="card-body">
                <h5 class="card-title">User ID {{ bayerOrderDetail.user_id }}</h5>
                <p class="card-text"><strong>First Name:</strong> {{ bayerOrderDetail.user.first_name }}</p>
                <p class="card-text"><strong>Comment:</strong> {{ bayerOrderDetail.comments }}</p>
                <p class="card-text"><strong>Required File:</strong> {{ bayerOrderDetail.seller_id }}</p>
                <div v-if="bayerOrderDetail.req_file_url">
                  <img :src="bayerOrderDetail.req_file_url" alt="Required File" class="img-fluid">
                  <button @click="downloadFile(bayerOrderDetail.req_file_url)" class="btn btn-sm btn-light ms-2 mt-2">
                    <i class="bi bi-download"></i> Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      myorders: [],
      allorders: [],
      userId: '',  // User ID from local storage
      packageDetails: [],
      bayerOrderDetails:[],
    };
  },
  created() {
    this.userId = localStorage.getItem('id');  // Retrieve user ID from local storage
    this.fetchOrders();

    this.fetchPackageDetails([1, 2, 3]);
    this.fetchBayerRequirement([1, 2, 3]);
  },
  methods: {
    async fetchOrders() {
      try {
        // Fetch live order packages for the user
        const response = await axios.get('/live-order-packages', {
          params: { userId: this.userId }
        });
        this.myorders = response.data;

        // Fetch orders for each live order ID
        await Promise.all(this.myorders.map(order => this.sendLiveOrderId(order.live_order_id)));
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    },
    async sendLiveOrderId(liveOrderId) {
      try {
        const response = await axios.post('/send-live-order-id', {
          live_order_id: liveOrderId
        });
        // Append fetched orders to the allorders array
        this.allorders.push(...response.data.orders);
        // Fetch package details for each order
        await this.fetchPackageDetails(response.data.orders.map(order => order.package_id));

        await this.fetchBayerRequirement(response.data.orders.map(order => order.live_order_id));
      } catch (error) {
        console.error('Error sending live order ID:', error);
      }
    },


  //   get Packages details from Packages Table
    async fetchPackageDetails(packageId) {
    try {
      const response = await axios.post('/fetch-package-details', {
        package_id: packageId
      });
      this.packageDetails = response.data.packageDetails;
    } catch (error) {
      console.error('Error fetching package details:', error);
    }
  },



      //   get bayer send data in liveOrder Table
      async fetchBayerRequirement(liveOrderId) {
          try {
      const response = await axios.post('/get-bayer-requirement', {
          live_order_id: liveOrderId
      });
      this.bayerOrderDetails = response.data.bayerOrderDetails;
    } catch (error) {
      console.error('Error bayer order details:', error);
    }
},

downloadFile(url) {
  const link = document.createElement('a');
  link.href = url;
  link.download = ''; // Optional: Set a default filename here if desired
  link.click();
},



  },


};
</script>

<style scoped>
.MainClass {
margin-top: 20px;
}

.firstClass{
  display: flex;
justify-content: center;
/* align-items: center; */
  width: 100%;
  gap: 20px;
}

.carSectionA, .carSectionB{
  width: 100%;
}

.carSection {
margin-bottom: 30px;

}
.cardOutline {
margin-bottom: 15px;
}
.flex-item {
flex: 1 1 calc(33.333% - 1rem);
box-sizing: border-box;
}

.img-fluid{
  width: 250px;
  height: 100;
  
}
@media (max-width: 768px) {
.flex-item {
  flex: 1 1 calc(50% - 1rem);
}
}
@media (max-width: 576px) {
.flex-item {
  flex: 1 1 100%;
}
}


</style>