<template>
  <header class="header">
    <div class="logo">
      <span>ProLance<b>.</b></span>
    </div>
    <div class="search-bar">
      <input type="text" placeholder="Search" />
      <button><i class="fas fa-search"></i></button>
    </div>
    <div v-if="isLoggedIn" class="header-logged-in">
      <div class="header-buttons">
        <span class="icon">🔔</span>
        <span class="icon">✉️</span>
        <!-- <router-link :to="{ name: 'ChatPage', params: { chatId: currentUserId } }" class="icon">✉️</router-link> -->
        <span class="text">Order</span>

        <div class="dropdown">
          <button
            class="btn btn-outline-light dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img :src="profilePhoto" alt="Profile Photo" class="profile-photo" />
            <span class="user-name">{{ firstName }}</span>
          </button>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li v-if="role !== 2 && !$route.path.includes('/sellerdashboard')">
              <router-link class="dropdown-item" to="/overview">Become a Seller</router-link>
            </li>
            <li>
              <button class="dropdown-item" @click="logout">Log Out</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else class="header-logged-out">
      <div class="header-buttons">
        <router-link to="/login"><button class="signin-btn">Sign In</button></router-link>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    isLoggedIn: Boolean,
    firstName: String,
    profilePhoto: String,
    role: Number,
    currentUserId: Number
  },
  mounted() {
    console.log('Current Role:', this.role);
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$emit('update:isLoggedIn', false);
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #081729;
  color: #fff;
  font-family: 'Poppins', sans-serif;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

.logo span {
  font-size: 1.5em;
  font-weight: 900;
}

.logo b {
  color: #7f00ff;
}

.search-bar {
  display: flex;
  align-items: center;
  width: 50%;
}

.search-bar input {
  width: 100%;
  padding: 5px 10px;
  border: none;
  border-radius: 4px 0 0 4px;
  outline: none;
}

.search-bar button {
  padding: 5px 10px;
  border: none;
  background-color: #7f00ff;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  color: #fff;
}

.header-logged-in,
.header-logged-out {
  display: flex;
  align-items: center;
}

.header-buttons {
  display: flex;
  align-items: center;
}

.header-buttons .icon {
  margin-left: 15px;
  font-size: 1.2em;
  cursor: pointer;
}

.header-buttons .text {
  margin-left: 15px;
  font-size: 1em;
  cursor: pointer;
}

.header-buttons .profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 15px;
}

.header-buttons .user-name {
  font-weight: bold;
  margin-left: 15px;
}

.header-buttons .logout-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 15px;
}

.header-buttons .logout-button:hover {
  background-color: #ff1a1a;
}

.header-buttons .signin-btn,
.header-buttons .signup-btn {
  background-color: #fff;
  color: #081729;
  margin-left: 15px;
}

.header-buttons .signin-btn:hover,
.header-buttons .signup-btn:hover {
  background-color: #081729;
  color: #fff;
}

.profile-photo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.user-name {
  font-weight: bold;
}
</style>
