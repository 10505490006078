<template>
    <div class="video-animation-page">
      <!-- Main Image Section -->
      <div class="main-image-section">
        <img src="@/assets/video/banner.jpg" alt="Video & Animation">
        <div class="overlay">
          <h1>Video & Animation</h1>
        </div>
      </div>
      
    
    <!-- Related Categories Section -->
    <div class="related-categories">
      <h2>Related Categories</h2>
      <div class="category-cards">
        <div
          class="category-card"
          v-for="category in categories"
          :key="category.id"
        
        >

        <!-- @click="navigateToPage(category.c_title)" -->

          <img :src="getCategoryImage(category.c_title)" :alt="category.c_title">
          <h3>{{ category.c_title }}</h3>
          <ul>
            <li v-for="sub in category.subcategories" :key="sub.id">
              <a @click.stop="navigateToPage(sub.sub_c_title, sub.id)">{{ sub.sub_c_title }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      categories: []
    };
  },
  mounted() {
    this.fetchCategories();
  },
  methods: {
    fetchCategories() {
      // Make an API call to fetch categories and subcategories from Laravel backend
      axios.get('/categories-Video')
        .then(response => {
          this.categories = response.data;
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
        });
    },
    getCategoryImage(c_title) {
      try {
        return require(`@/assets/video/${c_title}.jpg`);
      } catch (e) {
        // Return a default image or handle the error as needed
        return require('@/assets/video/banner.jpg');
      }
    },
    navigateToPage(sub_c_title, id) {
      // Navigate to the subcategory page with c_title and id as query parameters
      this.$router.push({ path: `/subcategory`, query: { sub_c_title: sub_c_title, sub_c_id: id } });
    }
  }
};
</script>

<style scoped>
.graphic-design-page {
  font-family: 'Poppins', sans-serif;
  color: #3d535f;
}

.main-image-section {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.main-image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-image-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-image-section .overlay h1 {
  color: #fff;
  font-size: 3em;
  text-transform: uppercase;
}

.related-categories {
  padding: 50px 10%;
  background-color: #f9f9f9;
}

.related-categories h2 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 30px;
}

.category-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.category-card {
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  margin: 20px;
  transition: transform 0.3s;
  text-align: center;
  cursor: pointer;
}

.category-card:hover {
  transform: translateY(-10px);
}

.category-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  margin-bottom: 10px;
}

.category-card h3 {
  font-size: 1.5em;
  color: #7f00ff;
  margin-bottom: 10px;
}

.category-card ul {
  list-style: none;
  padding: 0;
}

.category-card li {
  font-size: 1.1em;
  margin: 5px 0;
}
</style>

  