
<template>
    <div class="professional-info-page">
      <div class="header">
        <div class="Logo">ProLance<b>.</b></div>
      </div>
      <div class="content">
        <h3>Professional Information</h3>
        <form @submit.prevent="submitProfessionalInfo">
         
          <!-- <div class="form-group">
            <label for="profession">Profession</label>
            <select id="profession" v-model="profession" required>>
            <option value="">Select Profession</option>
              <option value="graphics & design">Graphics & Design</option>
              <option value="digital marketing">Digital Marketing</option>
              <option value="writing & translation">Writing & Translation</option>
              <option value="video animation">Video & Animation</option>
              <option value="music audio">Music & Audio</option>
              <option value="programming">Programming & Tech</option>
            </select>
          </div> -->

          
          <div class="form-group">
  <label for="profession">Profession</label>
  <select id="profession" class="form-select"  v-model="selectedProfession" required>
    <option value="" disabled>Select a Profession</option>
    <option v-for="profession in professions" :key="profession.id" :value="profession">{{ profession.title }}</option>
  </select>
</div>
        
          <div class="form-group">
            <label for="skills">Skills</label>
            <textarea id="skills" v-model="skills" required></textarea>
          </div>
          <button type="submit" class="continue-button">Submit</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    
    name: 'ProfessionalInfoPage',
    data() {
      return {
        selectedProfession: null, 
       
        skills: '',
        fullName: '',
        displayName: '',
        description: '',
        language: '',
        languageLevel: '',
        professions: [] ,
        
      };
    },
    created() {
      this.fetchProfessions();
      // Retrieve and set the query parameters
      this.fullName = this.$route.query.fullName || '';
      this.displayName = this.$route.query.displayName || '';
      this.description = this.$route.query.description || '';
      this.language = this.$route.query.language || '';
      this.languageLevel = this.$route.query.languageLevel || '';
      
      // Log the received data to the console
      console.log('Received Full Name:', this.fullName);
      console.log('Received Display Name:', this.displayName);
      console.log('Received Description:', this.description);
      console.log('Received Language:', this.language);
      console.log('Received Language Level:', this.languageLevel);
      
    },
    methods: {

      fetchProfessions() {
      axios.get('/getProfessions') // Replace with your actual endpoint
        .then(response => {
          this.professions = response.data; // Assuming your API returns an array of categories
        })
        .catch(error => {
          console.error('Error fetching professions:', error);
        });
    },


    submitProfessionalInfo() {
      if (this.selectedProfession) {
        // Extract profession id and title
        const profession_id = this.selectedProfession.id;
        const profession_title = this.selectedProfession.title;

        // Save to localStorage or state management
        localStorage.setItem('profession_id', profession_id);
        localStorage.setItem('profession_title', profession_title);
        localStorage.setItem('skills', this.skills);
        localStorage.setItem('fullName', this.fullName);
        localStorage.setItem('displayName', this.displayName);
        localStorage.setItem('description', this.description);
        localStorage.setItem('language', this.language);
        localStorage.setItem('languageLevel', this.languageLevel);

        console.log('Received Profession ID:', profession_id);
        console.log('Received Profession Title:', profession_title);
        console.log('Received Skills:', this.skills);

        // Navigate to the account page with all the data
        this.$router.push({
          path: '/account',
          query: {
            fullName: this.fullName,
            displayName: this.displayName,
            description: this.description,
            language: this.language,
            languageLevel: this.languageLevel,
            profession_id: profession_id,
            profession_title: profession_title,
            skills: this.skills,
          }
        });
      } else {
        console.error('No profession selected');
      }
    }
    }
  };
</script>
  
  <style scoped>
  .professional-info-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 20px;
  }
  
  .Logo {
    display: flex;
    font-size: 40px;
    font-weight: 800;
    color: #3d535f;
    letter-spacing: 3px;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .Logo b {
    font-size: 50px;
    color: #7f00ff;
  }
  
  .content {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-size: 1em;
    margin-bottom: 5px;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea {
    height: 100px;
  }
  
  .continue-button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 1em;
    color: #fff;
    background-color: #00ff22;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .continue-button:hover {
    background-color: #00b32d;
  }
  </style>
  