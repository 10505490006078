<template>
  <div class="container mt-5">
    <h1 class="mb-4">Hello Buyer Order Page</h1>
    <h3 class="mb-4">User ID: {{ userId }}</h3>
    <div v-if="liveOrders.length">
      <h3 class="mb-3">Your Active Orders:</h3>
      <ul class="list-group">
        <li v-for="order in liveOrders" :key="order.id" class="list-group-item">
          <div class="mb-3">
            <button @click="fetchOrderDetails(order.id)" class="btn btn-primary">
              Get Your Order: ${{ order.total_amount }}
            </button>
          </div>
          <div v-if="selectedOrderId === order.id">
            <div v-if="orderDetails.orderSubmissions.length" class="card mb-3 p-3">
              <h4 class="mb-3">Order Submissions:</h4>
              <ul class="list-group">
                <li v-for="submission in orderDetails.orderSubmissions" :key="submission.id" class="list-group-item">
                  <div v-if="submission.seller">
                    <p><strong>Seller Full Name:</strong> {{ submission.seller.full_name }}</p>
                    <p><strong>Seller ID:</strong> {{ submission.seller.id }}</p>
                  </div>
                  <div v-else>
                    <p><strong>Seller:</strong> No seller information available</p>
                  </div>
                  <p><strong>Submission ID:</strong> {{ submission.id }}</p>
                  <p><strong>Live Order ID:</strong> {{ submission.live_order_id }}</p>
                  <p><strong>Description:</strong> {{ submission.description }}</p>
                  <p><strong>Created At:</strong> {{ submission.created_at }}</p>
                  <p><strong>Updated At:</strong> {{ submission.updated_at }}</p>
                  <div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        :name="'order_' + order.id"
                        :id="'submission_' + submission.id"
                        :value="submission.id"
                        v-model="selectedSubmission[order.id]"
                      >
                      <label
                        class="form-check-label"
                        :for="'submission_' + submission.id"
                      >
                        Best Order
                      </label>
                    </div>
                  </div>
                  <p><strong>Submission Files:</strong></p>
                  <ul v-if="showFiles">
                    <li v-for="file in submission.submission_files" :key="file">
                      <a target="_blank" :href="file" :download="getFileName(file)">
                        <i class="bi bi-file-earmark"></i> {{ getFileName(file) }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <div v-if="selectedSubmission[order.id]">
                <button @click="handleConfirmOrder(order)" class="btn btn-success mt-3">CONFIRM ORDER</button>
              </div>
            </div>
            <div v-else>
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <p class="alert alert-warning">No submissions found for this order.</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-else>
      <p class="alert alert-warning">No orders found for this user.</p>
    </div>
    <div v-if="calculatedAmounts" class="card mt-4 p-3">
      <h4>Order Amount Distribution:</h4>
      <p><strong>Highest Price:</strong> ${{ calculatedAmounts.highestPrice }}</p>
      <p><strong>Service Charge:</strong> ${{ calculatedAmounts.serviceCharge }}</p>
      <p><strong>Total Amount:</strong> ${{ calculatedAmounts.total }}</p>
      <p><strong>Best Order (60%):</strong> ${{ calculatedAmounts.bestOrder }} (Seller ID: {{ calculatedAmounts.bestSellerId }})</p>
      <p><strong>Other Sellers (40% divided):</strong></p>
      <ul>
        <li v-for="amount in calculatedAmounts.otherSellers" :key="amount.sellerId">
          Seller ID: {{ amount.sellerId }} - ${{ amount.amount }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      userId: null,
      liveOrders: [],
      selectedOrderId: null,
      orderDetails: {
        liveOrderPackages: [],
        orderSubmissions: []
      },
      showFiles: false, // Track whether files should be shown
      selectedSubmission: {}, // Object to store selected submission IDs per order
      calculatedAmounts: null // Store calculated amounts
    };
  },
  mounted() {
    this.userId = localStorage.getItem('id'); // Retrieve user ID from local storage
    if (this.userId) {
      this.fetchLiveOrders();
    }
  },
  methods: {
    // handleConfirmOrder(order) {
    //   const highestPrice = order.highest_price;
    //   const serviceCharge = order.service_charge;
    //   const totalAmount = order.total_amount;
    //   const bestOrderId = this.selectedSubmission[order.id];
    //   const bestOrderAmount = (totalAmount * 0.6).toFixed(2);
    //   const sellerCount = this.orderDetails.orderSubmissions.filter(submission => submission.seller && submission.id !== bestOrderId).length;
    //   const perSellerAmount = (totalAmount * 0.4 / sellerCount).toFixed(2);

    //   const bestSellerId = this.orderDetails.orderSubmissions.find(submission => submission.id === bestOrderId).seller.id;
    //   const otherSellers = this.orderDetails.orderSubmissions
    //     .filter(submission => submission.seller && submission.id !== bestOrderId)
    //     .map(submission => ({
    //       sellerId: submission.seller.id,
    //       amount: perSellerAmount
    //     }));

        

    //   this.calculatedAmounts = {
    //     highestPrice: highestPrice, // Provided by backend
    //     serviceCharge: serviceCharge, // Provided by backend
    //     total: totalAmount,
    //     bestOrder: bestOrderAmount,
    //     bestSellerId: bestSellerId,
    //     otherSellers: otherSellers
    //   };

    //   this.showFiles = !this.showFiles; // Toggle file visibility
    // },


    handleConfirmOrder(order) {
  const orderId = order.id;
  const buyerId= order.user_id;
  const highestPrice = order.highest_price;
  const serviceCharge = order.service_charge;
  const totalAmount = order.total_amount;
  const bestOrderId = this.selectedSubmission[order.id];
  const bestOrderAmount = (totalAmount * 0.6).toFixed(2);
  const sellerCount = this.orderDetails.orderSubmissions.filter(submission => submission.seller && submission.id !== bestOrderId).length;
  const perSellerAmount = (totalAmount * 0.4 / sellerCount).toFixed(2);

  const bestSellerId = this.orderDetails.orderSubmissions.find(submission => submission.id === bestOrderId).seller.id;
  const otherSellers = this.orderDetails.orderSubmissions
    .filter(submission => submission.seller && submission.id !== bestOrderId)
    .map(submission => ({
      sellerId: submission.seller.id,
      amount: perSellerAmount
    }));

  const paymentData = {
    orderId: orderId,
    buyerId: buyerId,
    highestPrice: highestPrice,
    serviceCharge: serviceCharge,
    totalAmount: totalAmount,
    bestOrderAmount: bestOrderAmount,
    bestSellerId: bestSellerId,
    otherSellers: otherSellers
  };

  axios.post('/save-payment', paymentData)
    .then(response => {
      console.log('Payment data saved successfully', response);
      this.$router.push('/buyer');
    })
    .catch(error => {
      console.error('Error saving payment data', error);
    });

  this.calculatedAmounts = {
    highestPrice: highestPrice, // Provided by backend
    serviceCharge: serviceCharge, // Provided by backend
    total: totalAmount,
    bestOrder: bestOrderAmount,
    bestSellerId: bestSellerId,
    otherSellers: otherSellers
  };

  this.showFiles = !this.showFiles; // Toggle file visibility
},

    fetchLiveOrders() {
      axios.get(`/get-live-orders/${this.userId}`)
        .then(response => {
          this.liveOrders = response.data;
        })
        .catch(error => {
          console.error('Error fetching live orders:', error);
        });
    },
    fetchOrderDetails(orderId) {
      this.selectedOrderId = orderId;
      // Reset orderDetails to ensure no stale data
      this.orderDetails = {
        liveOrderPackages: [],
        orderSubmissions: []
      };
      axios.get(`/get-live-order-packages/${orderId}`)
        .then(response => {
          this.orderDetails = response.data;
        })
        .catch(error => {
          console.error('Error fetching order details:', error);
        });
    },
    getFileName(fileUrl) {
      return fileUrl.split('/').pop();
    }
  }
}
</script>

<style scoped>
.card {
  border-radius: 0.5rem;
}
.list-group-item {
  border: none;
  padding: 1rem;
  margin-bottom: 0.5rem;
  background-color: #f8f9fa;
}

.list-group-item:hover {
  background-color: #e9ecef;
}
</style>