<template>
  <div>
    <div>
        <FrontHeader />
      </div>

    <div>
      <BecomeSellerA />
    </div>
    <div>
      <FooterPart />
    </div>

  </div>
</template>

<script>
import FrontHeader from '@/components/FrontHeader.vue';
import BecomeSellerA from '@/components/becomeSeller/BecomeSellerA.vue';
import FooterPart from '@/components/FooterPart.vue';



  export default {

    components:{
    BecomeSellerA,
   
    FrontHeader,
    FooterPart,

}
    
  }
</script>

<style lang="scss" scoped>


</style>