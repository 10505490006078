<template>
  <div class="seller-dashboard container">
    <div class="row">
      <!-- Left column with user info -->
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body">
            <!-- User profile section -->
            <div class="d-flex align-items-start">
              <img :src="user.profile_photo" alt="Profile Photo" class="rounded-circle avatar-lg img-thumbnail" />
              <div class="w-100 ms-3">
                <h4 class="my-0">{{ user.first_name }}</h4>
                <p class="text-muted">{{ username }}</p>
                <button type="button" class="btn btn-soft-primary btn-xs waves-effect mb-2 waves-light">Sri Lanka</button>
                <button type="button" class="btn btn-soft-success btn-xs waves-effect mb-2 waves-light">Message</button>
              </div>
            </div>
            <!-- About me section -->
            <div class="mt-3">
              <h4 class="font-13 text-uppercase">About Me :</h4>
              <p class="text-muted font-13 mb-3">Hi, I'm {{ user.first_name }}. {{ user.seller.description }}</p>
              <p class="text-muted mb-2 font-13"><strong>Full Name :</strong> <span class="ms-2">{{ user.first_name }} {{ user.last_name }}</span></p>
              <p class="text-muted mb-1 font-13"><strong>Rating :</strong> <span class="ms-2">{{ sellerRating }}</span></p>
            </div>
          </div>
        </div>


        <div class="mycard">
          <div v-if="hasNewOrder">
            <button class="alert alert-primary" role="alert"   @click="goToOrder">
              You have a new order!
            </button>
          </div>

          <div v-else>
            <div class="alert alert-secondary" role="alert">
              No order at the moment.
            </div>    
          </div>
          <!-- Display messages based on order status -->
        </div>


        <!-- Inbox section -->
        <div class="card">
          <div class="card-body">
            <h4 class="header-title mb-3">Inbox</h4>
            <SellerChat/>
            <!-- <div class="inbox-widget" style="max-height: 350px; overflow-y: auto;">
              <div v-for="(message, index) in inboxMessages" :key="index" class="d-flex align-items-center pb-1">
                <img src="https://bootdey.com/img/Content/avatar/avatar2.png" class="rounded-circle img-fluid avatar-md img-thumbnail bg-transparent" alt="">
                <div class="w-100 ms-3">
                  <h5 class="mb-1">{{ message.sender }}</h5>
                  <p class="mb-0 font-13">{{ message.text }}</p>
                </div>
                <a href="javascript:void(0);" class="btn btn-sm btn-soft-info font-13" title="Reply"> <i class="mdi mdi-reply"></i> </a>
              </div>
            </div> -->
          </div>
        </div>
        
        <!-- Upcoming reminders section -->
        <div class="card">
          <div class="card-body">
            <h4 class="header-title mb-3">Upcoming Reminders <i class="mdi mdi-adjust ms-1"></i></h4>
            <div class="list-group">
              <a href="#" class="my-1" v-for="(reminder, index) in reminders" :key="index">
                <div class="d-flex align-items-start">
                  <div><i class="mdi mdi-circle h3 text-primary"></i></div>
                  <div class="w-100 ms-2">
                    <h5 class="mb-1 mt-0">{{ reminder.title }}</h5>
                    <ul class="list-inline text-muted font-12">
                      <li class="list-inline-item"><i class="mdi mdi-calendar-blank-outline me-1"></i>{{ reminder.date }}</li>
                      <li class="list-inline-item"> - </li>
                      <li class="list-inline-item"><i class="mdi mdi-clock-time-eight-outline me-1"></i>{{ reminder.time }}</li>
                    </ul>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Right column with opportunities -->
      <div class="col-xl-5">
        <div class="card">
          <div class="card-body">
            <h3>Welcome, {{ user.first_name }}!</h3>
          </div>
        </div>
            <div class="card">
              <div class="card-body">
            
            <!-- List of opportunities -->
            <div class="active-orders mt-3">
              <h4>Opportunities</h4>
              <ul>
                <li v-for="opportunity in user.opportunities" :key="opportunity.id">
                  <h3>{{ opportunity.opportunity_title }}</h3>
                  <p>{{ opportunity.c_title }}</p>

          <!-- Display opportunity images -->

          <!-- <div class="opportunity-images">
            <img v-for="image in opportunity.oppo_images"
                 :key="image.id"
                 :src="image.full_path"
                 :alt="opportunity.opportunity_title + ' Image'"
                 class="img-fluid"
            />
          </div> -->

          <div v-if="opportunity.oppo_images.length > 0" :id="'carousel' + opportunity.id" class="carousel slide" data-bs-touch="false">
          <div class="carousel-inner">
            <div v-for="(image, index) in opportunity.oppo_images" :key="image.id" :class="['carousel-item', { active: index === 0 }]">
              <img :src="image.full_path" class="d-block w-100 cardImage"  :alt="'Image for ' + opportunity.opportunity_title">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" :data-bs-target="'#carousel' + opportunity.id" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" :data-bs-target="'#carousel' + opportunity.id" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <div v-else>
          <p>No images available for this opportunity.</p>
        </div>
          
                </li>
              </ul>
            </div>
            
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import { ref, onMounted } from 'vue';
import { ref, onMounted, onUnmounted } from 'vue';
import SellerChat from '@/components/Dashboard/ChatPage/SellerChat.vue'

export default {
  name: 'SellerDashboard',

  components: {
    SellerChat

  },
  data() {
    return {
      user: {
        seller: {},
        opportunities: [],
      },
      username: '',
      sellerRating: 4.5,
      inboxMessages: [],
      reminders: [
        { title: 'New Admin Layout Discuss', date: '10 May 2021', time: '09:00am To 10:30am' },
        { title: 'Landing Pages Planning', date: '10 May 2021', time: '02:00pm To 4:00pm' },
        // Add more reminders as needed
      ]
    };
  },
  
  setup() {
        const hasNewOrder = ref(false);
        const token = localStorage.getItem('token');
        let intervalId;

        const checkLiveOrderStatus = async () => {
            try {
                const response = await axios.get('/live-order-package/status', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                const { status } = response.data;
                hasNewOrder.value = status === 1;
            } catch (error) {
                console.error('Error fetching live order status:', error);
                // Handle error, show appropriate message or take action
            }
        };

        onMounted(() => {
            if (token) {
                checkLiveOrderStatus();
                intervalId = setInterval(checkLiveOrderStatus, 2000); //refesh every 2 second
            } else {
                console.error('Token not found in local storage.');
                // Handle case where token is not available
            }
        });

        onUnmounted(() => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        });

        return {
            hasNewOrder
        };
    },
  methods: {
    async fetchUserData() {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/dashboard', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.user = response.data.user;
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    async fetchMessages() {
      try {
        const response = await fetch('/messages');
        if (!response.ok) throw new Error('Failed to fetch messages');
        const data = await response.json();
        this.messages = data.messages;
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },
    async fetchActiveOrders() {
      this.activeOrders = ['Gold', 'Silver', 'Premium'];
    },

  goToOrder () {
      this.$router.push({
        path: '/orderdetails'
      });
    }
  },
  mounted() {
    this.fetchMessages();
    this.fetchActiveOrders();
    this.fetchUserData();
  }
};
</script>

<style scoped>
body {
  background-color: #ecf2f5;
  margin-top: 20px;
}
.card {
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 10%);
  margin-bottom: 24px;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eff2f7;
  border-radius: 0.25rem;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}
.w-100 {
  width: 100% !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.avatar-md {
  height: 4rem;
  width: 4rem;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.bg-transparent {
  background-color: transparent !important;
}
.avatar-lg {
  height: 5rem;
  width: 5rem;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.font-13 {
  font-size: 13px !important;
}
.btn-soft-primary {
  color: #727cf5;
  background-color: rgba(114, 124, 245, .18);
}
.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: .75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.text-muted {
  color: #98a6ad !important;
}
.text-muted {
  color: #98a6ad !important;
}
.btn-soft-success {
  color: #0acf97;
  background-color: rgba(10, 207, 151, .18);
}
.text-uppercase {
  text-transform: uppercase !important;
}
h5 {
  font-size: .9375rem;
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125);
}

.cardImage {
  width: 100% ;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.carousel {
  width: 200px ;
}
</style>
