<template>
    <div>
<div class="body">
    <div class="box">
        <span class="border_line"></span>
        <form @submit.prevent="login">
            
            <h2>Sign in</h2>
            <div class="inputBox">
            <input type="text" v-model="email"
             required="required">
            <span>Username</span>
            <i></i>
         </div>
            <div class="inputBox">
            <input type="password" v-model="password" required="required">
            <span>Password</span>
            <i></i>
           </div> 
           <div class="link">
            <a href="#">Forgot Password</a>
           <router-link to = '/register'> Sigup</router-link>
            <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
           </div>
           
           <input type="submit"  value="Login">
          
        </form>

        </div>

      
</div>
    </div>
</template>

<script>
import axios from "axios";
export default {
  components: {
   
  },
  data() {
    return {
      email: "",
      password: "",
      firstName: "",
      profilePhoto: "",
      role: "",
      isLoggedIn: false,
      errorMessage: "",
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post("/login", {
          email: this.email, password: this.password,
        });

const { token, firstName, profilePhoto, role, id } = response.data;

        localStorage.setItem("token", token);
        this.firstName = firstName;
        this.profilePhoto = profilePhoto;
        this.role = role;
        this.id = id;
        this.isLoggedIn = true;
        localStorage.setItem("firstName", firstName);
        localStorage.setItem("profilePhoto", profilePhoto);
        localStorage.setItem("role", role);
        localStorage.setItem("id", id);
        localStorage.getItem("role", role);


        console.log(role);

        this.$emit('login-success', { firstName, profilePhoto, role, id });

        // this.$router.push({
        //   name: "buyer",
        //   query: {
        //     token,
        //   },
        // });

        if (role === 2) {
      this.$router.push({
        path: "/sellerdashboard",
        query: {
          token,
        },
      });
    } else {
      this.$router.push({
        name: "buyer",
        query: {
          token,
        },
      });
    }
      } catch (error) {
        this.errorMessage = "Login failed: " + (error.response?.data?.message || error.message);
      }
    }
  }
}

  
        
   
</script>

<style lang="scss" scoped>

@import url('https://fonts.googleapis.com/css2? family=Poppins:wght@300;400;500;600;700;800;900&display=swap');


*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif,'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    
}
.body{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #23242a;
}

.box{
    position:relative;
    width: 380px;
    height: 420px;
    background: #1c1c1c;
    border-radius: 8px;
    overflow: hidden;
}
.box::before{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg, transparent, transparent, #45f3ff,#45f3ff,#45f3ff);
    z-index: 1;
    transform-origin: bottom right ;
    animation: animate 6s linear infinite;
}
.box::after{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg, transparent, transparent, #45f3ff,#45f3ff,#45f3ff);
    z-index: 1;
    transform-origin: bottom right ;
    animation: animate 6s linear infinite;
    animation-delay: -3s;
}

.border_line{
    position: absolute;
    top: 0;
    inset:0;
}
.border_line::before{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg, transparent, transparent, #ff2770,#ff2770,#ff2770);
    z-index: 1;
    transform-origin: bottom right ;
    animation: animate 6s linear infinite;
    animation-delay: -1.5s ;
}
.border_line::after{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 380px;
    height: 420px;
    background: linear-gradient(0deg, transparent, transparent, #ff2770,#ff2770,#ff2770);
    z-index: 1;
    transform-origin: bottom right ;
    animation: animate 6s linear infinite;
    animation-delay: -4.5s ;
}
@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.box form{
    position: absolute;
    inset:4px;
    background: #222;
    padding: 50px 40px;
    border-radius: 8px;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

.box form h2{
    color: #fff;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.1em;
}
.box form .inputBox{
    position:relative;
    width: 300px;
    margin-top: 35px;
}
.box form  .inputBox input{
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    color: #23242a;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    z-index: 10;

}
.box form .inputBox span{
    position: absolute;
    left: 0;
    padding: 20px 0px 10px;
    pointer-events: none;
    color: #8f8f8f;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
}
.box form .inputBox input:valid ~ span,
.box form .inputBox input:focus ~ span {
    color: #fff;
    font-size: 0.75em;
    transform: translateY(-34px);
}
.box form .inputBox i {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    border-radius: 4px ;
    overflow: hidden;
    transition: 0.5s;
    pointer-events: none;
} 

.box form .inputBox input:valid ~ i,
.box form .inputBox input:focus ~ i {
    height: 44px;
}

.box form .link {
    display: flex;
    justify-content: space-between;
}

.box form .link a {
    margin: 10px 0;
    font-size: 0.75em;
    color: #8f8f8f;
    text-decoration: none;
}
.box form .link a:hover,
.box form .link a:nth-child(2) {
    color: #fff;
}

.box form  input[type="submit"] {
    border: none;
    outline: none;
    padding: 9px 25px;
    background: #fff;
    cursor: pointer;
    font-size: 0.9em;
    border-radius: 4px;
    font-weight: 600;
    width: 100px;
    margin-top: 10px;
}

.box form input[type="submit"]:active {
    opacity: 0.8;
}



</style>