<template>
  <div>
    <CategoriesHeader
      :isLoggedIn="isLoggedIn"
      :firstName="firstName"
      :profilePhoto="profilePhoto"
      :role="role"
      @update:isLoggedIn="handleLoginState"
    />
    <div class="mainClass">
      <div class="sidebar">
        <h2>Order Dashboard</h2>
        <nav>
          <ul>
            <li><a href="#" @click.prevent="showPage('page-a')">Requirement</a></li>
            <li><a href="#" @click.prevent="showPage('page-b')">Sample Submittion</a></li>
            <li><a href="#" @click.prevent="showPage('page-c')">Order Submission</a></li>
            <li><a href="#" @click.prevent="showPage('page-d')">Payment</a></li>
          </ul>
        </nav>
      </div>

      <div class="main-content">
        <div v-if="currentPage === 'page-a'">
          <RequirementPart />
        </div>
        <div v-if="currentPage === 'page-b'">
          <SampleSubmission />
        </div>
        <div v-if="currentPage === 'page-c'">
          <OrderSubmission />
        </div>
        <div v-if="currentPage === 'page-d'">
          <OrderPayment />
        </div>
       
      </div>
      
    </div>
   
  </div>
   <div><FooterPart/></div>
</template>

<script>
import CategoriesHeader from '@/components/categories/CategoriesHeader.vue';
import RequirementPart from '@/components/Dashboard/SellerSubmission/RequirementPart.vue';
import OrderSubmission from '@/components/Dashboard/SellerSubmission/OrderSubmission.vue';
import SampleSubmission from '@/components/Dashboard/SellerSubmission/SampleSubmission.vue';
import OrderPayment from '@/components/Dashboard/SellerSubmission/OrderPayment.vue';
import FooterPart from '@/components/FooterPart.vue';

export default {
  components: {
    CategoriesHeader,
    RequirementPart,
    OrderSubmission,
    SampleSubmission,
    OrderPayment,
    FooterPart
  },
  data() {
    return {
      currentPage: 'page-a',
      isLoggedIn: false,
      firstName: '',
      profilePhoto: '',
      role: '',
    };
  },
  methods: {
    showPage(page) {
      this.currentPage = page;
    },
    handleLoginState(value) {
      this.isLoggedIn = value;
      if (!value) {
        this.firstName = '';
        this.profilePhoto = '';
        this.role = '';
      } else {
        this.firstName = localStorage.getItem('firstName') || '';
        this.profilePhoto = localStorage.getItem('profilePhoto') || '';
        this.role = localStorage.getItem('role') || '';
      }
    },
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token) {
      this.isLoggedIn = true;
      this.firstName = localStorage.getItem('firstName') || '';
      this.profilePhoto = localStorage.getItem('profilePhoto') || '';
      this.role = localStorage.getItem('role') || '';
    }
  },
};
</script>

<style lang="scss" scoped>
.mainClass {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: #540a66;
  color: white;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  border-top: 2px solid white;
}

.sidebar h2 {
  color: white;
  margin: 0 0 20px;
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.sidebar nav ul li {
  margin: 20px 0;
}

.sidebar nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding: 10px;
  display: block;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar nav ul li a:hover {
  background-color: white;
  color: #14b3cf;
}

.main-content {
  flex: 1;
  background-color: rgb(238, 238, 241);
  padding: 20px;
  overflow-y: auto;
  color: #3d535f;
  border-left: 2px solid #14b3cf;
}

.main-content h1 {
  color: #3d535f;
}
</style>
