<template>
  <div class="body">
    <div class="container">
      <div class="wrapper">
        <div class="cols cols0">
          <span class="topline">Begin your journey</span>
          <h1>As A <span class="multiText"></span></h1>
          <p>Welcome to our freelance marketplace...! Whether you're a designer, 
              photographer, programmer, or have any other skill, this is your 
              platform to shine. Join our community of talented freelancers and 
              embark on a journey of creativity and success.</p>
          <!-- <div class="btns">
            <router-link to="/overview">
              <button>Become a seller</button>
            </router-link>
          </div> -->


          <div class="btns">
    <button @click="handleClick">{{ buttonName }}</button>
  </div> 
        </div>
        <div class="cols cols1">
          <div class="imgbox">
            <img src="../../assets/seller/img01.png">
          </div>
        </div>
      </div>
      <!-- How It Works Section -->
      <div class="how-it-works">
        <h2>How It Works</h2>
        <div class="steps">
          <div class="step">
            <i class="fas fa-pencil-alt"></i>
            <h3>Create Gig</h3>
            <p>Set up your profile, showcase your skills, and create your gigs to attract clients.</p>
          </div>
          <div class="step">
            <i class="fas fa-briefcase"></i>
            <h3>Deliver Work</h3>
            <p>Communicate with clients, work on projects, and deliver high-quality work on time.</p>
          </div>
          <div class="step">
            <i class="fas fa-dollar-sign"></i>
            <h3>Get Paid</h3>
            <p>Receive payments for your completed work and grow your freelance business.</p>
          </div>
        </div>
      </div>
      <!-- Q&A Section -->
      <div class="qa-section">
        <h2>Q&A</h2>
        <div class="qa">
          <div class="qa-item" @click="toggleAnswer(0)">
            <div class="question">
              <h3>What is ProLance?</h3>
              <span v-if="activeIndex === 0">-</span>
              <span v-else>+</span>
            </div>
            <div class="answer" v-if="activeIndex === 0">
              <p>ProLance is a freelance marketplace where you can showcase your skills, find projects, and connect with clients.</p>
            </div>
          </div>
          <div class="qa-item" @click="toggleAnswer(1)">
            <div class="question">
              <h3>How do I create a gig?</h3>
              <span v-if="activeIndex === 1">-</span>
              <span v-else>+</span>
            </div>
            <div class="answer" v-if="activeIndex === 1">
              <p>To create a gig, set up your profile, showcase your skills, and follow the prompts to create and publish your gig.</p>
            </div>
          </div>
          <div class="qa-item" @click="toggleAnswer(2)">
            <div class="question">
              <h3>How do I get paid?</h3>
              <span v-if="activeIndex === 2">-</span>
              <span v-else>+</span>
            </div>
            <div class="answer" v-if="activeIndex === 2">
              <p>You get paid after you deliver the work and the client approves it. Payments are processed through our secure payment system.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Typed from 'typed.js';
import '@fortawesome/fontawesome-free/css/all.css';

export default {
  data() {
    return {
      activeIndex: null,

      buttonName: "Become a seller" // Initial button name
    };
  },
  mounted() {
    new Typed(".multiText", {
      strings: ["Singer", "Programmer", "Designer"],
      loop: true,
      typeSpeed: 100,
      backSpeed: 80,
      backDelay: 1500
    });
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },

    handleClick() {
      // Get the button name
      const nameToSend = this.buttonName;

      // Encode the button name for URL
      const encodedName = encodeURIComponent(nameToSend);

      // Navigate to the register page with the encoded button name as a query parameter
      this.$router.push({ path: '/register', query: { name: encodedName } });
    }

  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif, "Arial", sans-serif;
}

.body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container {
  width: 100%;
  padding: 0;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 50px 5%;
  position: relative;
  z-index: 10;
}

.wrapper::before,
.wrapper::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  z-index: -1;
}

.wrapper::before {
  height: 600px;
  width: 600px;
  left: -12%;
  top: 40%;
  background: linear-gradient(45deg, #7f00ff, pink);
  animation: object1 6s linear infinite;
}

.wrapper::after {
  height: 150px;
  width: 150px;
  left: -20%;
  top: 0;
  background: linear-gradient(45deg, #0f25ec, pink);
  animation: object2 6s linear infinite;
}

@keyframes object1 {
  50% {
    left: -13%;
    top: 41%;
  }
}

@keyframes object2 {
  50% {
    left: 35%;
    top: 10%;
  }
}

.cols0 {
  flex: 1;
  padding: 20px;
}

.cols {
  z-index: 5;
  margin-top: 80px;
}

.topline {
  display: block;
  position: relative;
  font-size: 35px;
  letter-spacing: 5px;
  color: #3d535f;
}

.topline::after {
  position: absolute;
  content: '';
  height: 4px;
  width: 45px;
  bottom: 10px;
  background-color: #7f00ff;
}

h1 {
  display: block;
  font-size: 7em;
  font-weight: 900;
  color: #3d535f;
  font-size: 100px;
}

.multiText {
  color: #7f00ff;
  text-transform: capitalize;
}

p {
  display: block;
  width: 90%;
  font-size: 1.2em;
  color: #3d535f;
}

.btns {
  width: 100%;
  position: relative;
  left: 150px;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 400;
  color: #fff;
  background-color: #3d535f;
  padding: 8px 14px;
  margin: 40px 5px;
  letter-spacing: 2px;
  text-transform: capitalize;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.4);
}

.imgbox {
  position: relative;
  width: 100%;
  height: 100%;
}

.imgbox img {
  position: relative;
  height: 100%;
  width: auto;
  top: -100px;
  right: 10px;
  margin-left: auto;
  animation: animateUser 4s linear infinite;
}

@keyframes animateUser {
  50% {
    right: 30px;
    top: -90px;
  }
}

.how-it-works {
  width: 100%;
  text-align: center;
  padding: 50px 5%;
  background-color: #f3f5f8;
  margin-top: 50px;
}

.how-it-works h2 {
  font-size: 2.5em;
  color: #3d535f;
  margin-bottom: 20px;
}

.steps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.step {
  width: 30%;
  margin: 20px 0;
}

.step i {
  font-size: 2.5em;
  color: #7f00ff;
  margin-bottom: 10px;
}

.step h3 {
  font-size: 1.8em;
  color: #7f00ff;
  margin-bottom: 10px;
}

.step p {
  font-size: 1.2em;
  color: #3d535f;
}

.qa-section {
  width: 100%;
  padding: 50px 5%;
  text-align: center;
  background-color: #fff;
  margin-top: 50px;
}

.qa-section h2 {
  font-size: 2.5em;
  color: #3d535f;
  margin-bottom: 20px;
}

.qa {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qa-item {
  width: 80%;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
  cursor: pointer;
}

.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question h3 {
  font-size: 1.8em;
  color: #3d535f;
  margin: 0;
}

.answer {
  display: none;
  font-size: 1.2em;
  color: #3d535f;
  margin-top: 10px;
}

.qa-item.active .answer {
  display: block;
}

.qa-item .question span {
  font-size: 1.5em;
  color: #7f00ff;
}
</style>
