<template>
    <div>
      <div>
       
      </div>
      <CategoriesHeader 
      :isLoggedIn="isLoggedIn"
        :firstName="firstName"
        :profilePhoto="profilePhoto"
        :role="role"
        @update:isLoggedIn="handleLoginState"/>
  
      <div>
        <WritingTranslation/>
      </div>
      <div>
        <FooterPart />
      </div>
    
  
    </div>
  </template>
  
  <script>
  
  import FooterPart from '@/components/FooterPart.vue';
  import WritingTranslation from '@/components/categories/writing/WritingTranslation.vue';
import CategoriesHeader from '@/components/categories/CategoriesHeader.vue';
 
  
  
    export default {
      
  
      components:{
        CategoriesHeader,
       
        WritingTranslation ,
      FooterPart
      },
      
      data() {
    return {
      isLoggedIn: false,
      firstName: '',
      profilePhoto: '',
      role: '',
    };
  },
  methods: {
    handleLoginState(value) {
      this.isLoggedIn = value;
      if (!value) {
        this.firstName = '';
        this.profilePhoto = '';
        this.role = '';
      } else {
        this.firstName = localStorage.getItem('firstName') || '';
        this.profilePhoto = localStorage.getItem('profilePhoto') || '';
        this.role = localStorage.getItem('role') || '';
      }
    }
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token) {
      this.isLoggedIn = true;
      this.firstName = localStorage.getItem('firstName') || '';
      this.profilePhoto = localStorage.getItem('profilePhoto') || '';
      this.role = localStorage.getItem('role') || '';
    }
  }
      
}
      
    
  </script>
  
  <style lang="scss" scoped>
  
  
  </style>