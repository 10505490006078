<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div>
      <FrontHeader />
    </div>
    <div>
      <SecondPart />
    </div>
    <div>
      <ThirdPart />
    </div>
    <div>
      <FooterPart />
    </div>

    
  </div>

</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import FrontHeader from '@/components/FrontHeader.vue'
import SecondPart from '@/components/SecondPart.vue'
import ThirdPart from '@/components/ThirdPart.vue'
import FooterPart  from '@/components/FooterPart.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld,
    FrontHeader,
    SecondPart,
    ThirdPart,
    FooterPart 
  }
}
</script>
